import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'store'
import { FieldType } from 'types/slices'
import { DefaultRecord } from 'types/utils'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SmartGridColumnType } from 'components/alix-front/smart-grid/columns/utils'
import { SmartGridTextInput } from 'components/alix-front/smart-grid-input/types/SmartGridTextInput'

import { DevLogs } from 'utils/devLogs'
import { EntityType } from 'utils/entities'
import { useSmartFormInput } from 'utils/useSmartFormInput'

import './style.css'

type SmartGridInputType = 'text'
type SupportedTypes = string

export type DefaultSmartGridInputProps<
  EntityApi extends DefaultRecord,
  Entity extends DefaultRecord,
  T extends SupportedTypes
> = {
  onChange: (value: T) => void;
  value: string;
  isLoading: boolean;
  isEdit: boolean;
  column: SmartGridColumnType<Entity>;
  field: FieldType<EntityApi, Entity>;
  entity: EntityType<Entity>;
}

export type SmartGridInputRef = {
  focus: () => void;
}

type SmartGridInputProps<
  EntityApi extends DefaultRecord,
  Entity extends DefaultRecord,
> = {
  column: SmartGridColumnType<Entity>;
  field: FieldType<EntityApi, Entity>;
  entity: EntityType<Entity>;
  data: Entity;
  type: SmartGridInputType;
};

export function SmartGridInput<
  EntityApi extends DefaultRecord,
  Entity extends DefaultRecord,
  T extends SupportedTypes
>({ column, field, entity, data, type }: SmartGridInputProps<EntityApi, Entity>) {
  const { clearFilter, isEdit, setFilter } = useSmartFormInput({
    rowEntityId: data.id,
    columnName: String(column.field),
  })

  const toastRef = useAppSelector((state) => state.refs.refs.toastRef)
  const ref = useRef<HTMLDivElement>(null)

  const [originalValue, setOriginalValue] = useState<T | null>(null)
  const [value, setValue] = useState<T | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const hasChanged = useMemo(() => originalValue !== value, [originalValue, value])

  const { t } = useTranslation()

  useEffect(() => {
    const _value = data[column.field]

    setValue(_value)
    setOriginalValue(_value)
  }, [column.field, data])

  useEffect(() => {
    // If we change to `isEdit=False`, we need to reset the value to the original value
    if (!isEdit && originalValue !== value) {
      setValue(originalValue)
    }
  }, [column.field, data, isEdit, originalValue, value])

  const { updater } = entity

  const handleOnSave = useCallback(
    async() => {
      setIsLoading(true)

      const updates = [{
        [field.dbField]: value,
      }]

      const response = await updater?.([data.id], updates, {})

      if (!response.length) {
        setIsLoading(false)

        toastRef.current.show({ severity: 'error', summary: t('common:toastMessage.fetch.put.error') })

        return
      }

      const [updated] = response

      const newValue = updated[column.field]
      setValue(newValue)
      setOriginalValue(newValue)

      setIsLoading(false)
      clearFilter()

      toastRef.current.show({ severity: 'success', summary: t('common:toastMessage.fetch.put.successWithoutTitle') })
    },
    [clearFilter, column.field, data.id, field.dbField, t, toastRef, updater, value],
  )

  const handleOnChange = useCallback((value: any) => setValue(value), [])

  const handleCancelEdit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()

      clearFilter()
      setValue(originalValue)
    },
    [clearFilter, originalValue],
  )

  const _handleOnSave = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()

    handleOnSave()
  }, [handleOnSave])

  const handleCellClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()

      if (!isEdit) {
        setFilter()
      }
    },
    [isEdit, setFilter],
  )

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (ref.current && ref.current.contains(e.relatedTarget)) {
        return // Focus is still within the div, do not trigger blur logic
      }

      e.stopPropagation()

      clearFilter()
    },
    [clearFilter],
  )

  const input = useMemo(() => {
    if (!isEdit) {
      return (
        <span className="a-smart-grid-input-readonly-value">
          {value}
        </span>
      )
    }

    switch (type) {
    case 'text':
      return (
        <SmartGridTextInput<EntityApi, Entity>
          onChange={handleOnChange}
          value={value}
          isLoading={isLoading}
          isEdit={isEdit}
          column={column}
          field={field}
          entity={entity}
        />
      )
    default:
      DevLogs.error(
        'SmartGridInput',
        `Type \`${type}\` is not supported`,
        type,
      )
      return null
    }
  }, [column, entity, field, handleOnChange, isEdit, isLoading, type, value])

  const handleOnKeyUp = useCallback((ev) => {
    if (ev.key === 'Enter') {
      handleOnSave()
    } else if (ev.key === 'Escape') {
      clearFilter()
    }
  }, [clearFilter, handleOnSave])

  if (!updater) {
    DevLogs.error(
      'SmartGridTextInput',
      'Entity does not have an updater',
      entity,
    )

    return (
      <span>
        {value}
      </span>
    )
  }

  return (
    <div
      className="a-smart-grid-input"
      data-type="text"
      onClick={handleCellClick}
      data-is-edit={isEdit}
      onKeyUp={handleOnKeyUp}
      onBlur={handleOnBlur}
      ref={ref}
    >
      {input}
      <div className="a-smart-grid-input-actions-container">
        {isLoading ? (
          <div className="a-flex a-align-center a-justify-center a-yellow">
            <FontAwesomeIcon
              icon={['fad', 'spinner-third']}
              spin
              fixedWidth
            />
          </div>
        ) : isEdit ? (
          <>
            <button
              onClick={_handleOnSave}
              disabled={isLoading || !hasChanged}
              type="button"
              className="a-smart-grid-input-action-button a-green-button"
              title={t('common:button.save')}
            >
              <FontAwesomeIcon
                icon={['fad', 'check']}
                fixedWidth

              />
            </button>
            <button
              onClick={handleCancelEdit}
              disabled={isLoading}
              type="button"
              className="a-smart-grid-input-action-button a-red-button"
              title={t('common:button.cancel')}
            >
              <FontAwesomeIcon
                icon={['fas', 'xmark']}
                fixedWidth
              />
            </button>
          </>
        ) : (
          <div className="a-flex a-align-center a-justify-center">
            <FontAwesomeIcon
              icon={['far', 'pen']}
              fixedWidth
              className="a-smart-grid-input-edit-button"
            />
          </div>
        )}
      </div>
    </div>
  )
}
