import React, { useCallback, useMemo } from 'react'

import { useAppSelector } from 'store'

import { DevLogs } from 'utils/devLogs'
import { EntityName, getEntityObject } from 'utils/entities'
import { useBaseUrl } from 'utils/useBaseUrl'

type SmartEntityLinkProps = {
  entityName: EntityName;
  id: string;
  className?: string;
}

export function SmartEntityLink({
  entityName,
  id,
  className,
  children,
}: React.PropsWithChildren<SmartEntityLinkProps>) {
  const baseUrl = useBaseUrl()

  const iframedFullcardRef = useAppSelector((state) => state.refs.refs.iframedFullcardRef)

  const entity = useMemo(() => getEntityObject(entityName), [entityName])

  const _entityLinkData = useMemo(() => {
    const linkType = entity?.linkType

    const validId = !!id
    const supported = !!linkType

    /**
     * We only want to warn if the entity is not supported (does not have a linkType in the entity object).
     *
     * We assumed that an undefined id is normal, but will not be render as a link.
     */
    if (!supported) {
      DevLogs.warn('SmartEntityLink', `Entity \`${entityName}\` is not supported.`)
    }

    return {
      supported: supported && validId,
      linkType: linkType,
    }
  }, [entity?.linkType, entityName, id])

  const navigationUrl = useMemo(() => {
    if (!_entityLinkData.supported) return

    const linkType = _entityLinkData.linkType

    if (linkType.type === 'fullcard') return

    return `${baseUrl}${linkType.url}/${id}`
  }, [_entityLinkData.linkType, _entityLinkData.supported, baseUrl, id])

  const handleOnClick = useCallback(() => {
    if (!_entityLinkData.supported) return

    const linkType = _entityLinkData.linkType

    if (linkType.type !== 'fullcard') return

    iframedFullcardRef.current?.open(entityName, id)
  }, [_entityLinkData.supported, _entityLinkData.linkType, iframedFullcardRef, entityName, id])

  const classNames = useMemo(() => {
    const classes = ['a-pre-wrap', 'a-smart-grid-default-template']

    if (className) classes.push(className)
    if (_entityLinkData.supported) classes.push('a-clickable-link')

    return classes.join(' ')
  }, [_entityLinkData.supported, className])

  if (!_entityLinkData.supported || _entityLinkData.linkType.type === 'fullcard') {
    return (
      <span
        className={classNames}
        onClick={handleOnClick}
      >
        {children}
      </span>
    )
  } else {
    return (
      <a
        className={classNames}
        href={navigationUrl}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    )
  }
};
