import { BaseEntity, BaseEntityApi, FieldType, GetFields } from 'types/slices'
import { DefaultRecord, getKeys } from 'types/utils'

import { toCamel } from 'utils/stringUtils'

export type BaseEntityGetFields = GetFields<
  BaseEntityApi,
  BaseEntity
>;

export function getBaseEntityFields<T>({ dataSetName }: { dataSetName: string }) {
  return function() {
    return {
      id: { dataSetName, dbField: 'id' },
      exist: { dataSetName, dbField: 'exist', type: 'boolean' },
      createdDate: { dataSetName, dbField: 'created_date', type: 'timestamp' },
      modifiedDate: { dataSetName, dbField: 'modified_date', type: 'timestamp' },
      createdBy: { dataSetName, dbField: 'created_by' },
      createdById: { dataSetName, dbField: 'created_by_id', type: 'id', relationEntity: 'resources' },
      modifiedBy: { dataSetName, dbField: 'modified_by' },
      modifiedById: { dataSetName, dbField: 'modified_by_id', type: 'id', relationEntity: 'resources' },
    } as T
  }
}

export type FormatFieldsOptions = {
  isCamelCase?: boolean
}

export function formatFields(
  fields: DefaultRecord,
  prefix: string,
  trimAlias: string,
  defaultFieldInfo: Partial<FieldType>,
  options?: FormatFieldsOptions,
) {
  options = {
    isCamelCase: false,
    ...options,
  }

  const _fields = { ...fields }

  return getKeys(fields)
    .reduce((acc, key) => {
      const newKey = options.isCamelCase ?
        toCamel(`${prefix}_${key}`) :
        `${prefix}${key}`

      const fieldInfo = _fields[key]
      let _trimAlias = trimAlias
      let dataSetAlias = undefined

      if (fieldInfo.dataSetAlias) {
        dataSetAlias = `${trimAlias}_${fieldInfo.dataSetAlias}`
        _trimAlias = undefined
      }

      acc[newKey] = {
        ..._fields[key],
        ...defaultFieldInfo,
        dataSetAlias,
        trimAlias: _trimAlias,
        baseKey: key,
        updateDbField: fieldInfo.dbField,
      }

      return acc
    }, {})
}

export function filterFields<
  F extends Record<string, {dbField?: string}>,
  K extends string
>(
  fields: F,
  keysToKeep: readonly string[],
  overrideField: DefaultRecord = {},
  isEditFields?: (keyof F)[],
): Pick<F, K> {
  if (keysToKeep.length === 0) {
    return fields as any
  }

  return Object
    .entries(fields)
    .reduce((acc, [key, field]) => {
      const toKeep = keysToKeep.includes(key)

      if (toKeep) {
        acc[key] = {
          ...field,
          ...overrideField,
        }

        if (isEditFields) {
          acc[key].isEdit = isEditFields.includes(key as any)
        }
      }

      return acc
    }, {} as any)
}

