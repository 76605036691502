
import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'
import { ApiToSlice, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { buildDictionary } from 'utils/mapperHelper'
import { safeFetchJson, isJob, safeFetch } from 'utils/safeFetch'

import { fetchEquipmentsByIds, Equipment } from 'reducers/equipments/equipmentsSlice'
import { ReadingRegister } from 'reducers/readings-register/readingRegisterSlice'

const dataSetName = 'device'

const initialState: {
  dataSetName: string;
  fields: DeviceGetFields;
  readingRegisterIds: ReadingRegister['id'][];
} = {
  dataSetName,
  fields: getFields(),
  readingRegisterIds: [],
}

const devicesSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    addReadingRegisterId: (state, action) => {
      state.readingRegisterIds.push(action.payload)
    },
    resetReadingRegisterIds: (state) => {
      state.readingRegisterIds = []
    },
  },
})

export const {
  addReadingRegisterId,
  resetReadingRegisterIds,
} = devicesSlice.actions

export default devicesSlice.reducer

type MapData = {
  equipmentDict: Record<string, Equipment>
}

type DeviceConfigApi = {
  on_delay: string
  off_delay: string
  on_threshold: string
  off_threshold: string
  continuous_mode: boolean
}

type DeviceConfig = ApiToSlice<DeviceConfigApi>

export type DeviceApi = {
  id: string
  cid: string
  exist: boolean
  created_date: string
  modified_date: string
  uid: string
  equipment_id: number
  config: DeviceConfigApi
  lastseen: string
  sw_ver: string
  sw_url: string
  hostname: string
  type: string
  is_connected: boolean
  last_reading: string
  name: string
  owner_id: string
  status: string
  manufacturer: string
  model: string
  serial_number: string
  photo_filename: string
  description: string
  communication_type: string
  mac: string
}

type Exceptions = {photo_filename: 'photoFileName'}

export type Device = ApiToSlice<
  Modify<DeviceApi, {display_title: string, equipment: Equipment, config: DeviceConfig}>,
  Exceptions
>

type DeviceGetFields = GetFields<DeviceApi, Device, MapData>

export function getFields(): DeviceGetFields {
  return {
    id: { dataSetName, dbField: 'id', type: 'id' },
    exist: { dataSetName, dbField: 'exist', type: 'boolean' },
    uid: { dataSetName, dbField: 'uid' },
    createdDate: { dataSetName, dbField: 'created_date', type: 'date' },
    modifiedDate: { dataSetName, dbField: 'modified_date', type: 'date' },
    equipmentId: { dataSetName, dbField: 'equipment_id', type: 'id', relationEntity: 'equipments' },
    config: { dataSetName, dbField: 'config', type: 'json' },
    lastseen: { dataSetName, dbField: 'lastseen', type: 'date' },
    swVer: { dataSetName, dbField: 'sw_ver' },
    swUrl: { dataSetName, dbField: 'sw_url' },
    hostname: { dataSetName, dbField: 'hostname' },
    type: { dataSetName, dbField: 'type' },
    isConnected: { dataSetName, dbField: 'is_connected', type: 'boolean' },
    lastReading: { dataSetName, dbField: 'last_reading' },
    name: { dataSetName, dbField: 'name' },
    ownerId: { dataSetName, dbField: 'owner_id', type: 'id', relationEntity: 'resources' },
    status: { dataSetName, dbField: 'status' },
    manufacturer: { dataSetName, dbField: 'manufacturer' },
    model: { dataSetName, dbField: 'model' },
    serialNumber: { dataSetName, dbField: 'serial_number' },
    photoFileName: { dataSetName, dbField: 'photo_filename' },
    description: { dataSetName, dbField: 'description' },
    communicationType: { dataSetName, dbField: 'communication_type' },
    mac: { dataSetName, dbField: 'mac' },
    equipment: { parse: (device, { equipmentDict }) => equipmentDict?.[device.equipment_id] },
    displayTitle: { parseWithParsedData: _parseDisplayTitle },
  }
}

export type FetchOptions = {
  type?: string
  isEquipment?: boolean
}

export async function fetchDevices(data?: FetchOptions) {
  let devices: Device[] = []
  try {
    const { isSuccess, result } = await safeFetchJson<DeviceApi>(buildGetUrl('/api/iiot/devices', data))
    if (isSuccess && !isJob(result)) {
      const equipmentIds = result
        .filter((device) => device.equipment_id)
        .map((device) => device.equipment_id.toString())

      let equipmentDict: MapData['equipmentDict']
      if (data?.isEquipment && equipmentIds.length) {
        equipmentDict = buildDictionary(await fetchEquipmentsByIds(equipmentIds), 'id')
      }

      devices = result.map((device) => parseDevice(device, equipmentDict))
    }
  } catch (err) {
    console.error(err)
  }

  return devices
}

export type ReadScaleEntityData = {
  entityName: string
  entityId?: string
}

export function readScale(scaleId: string, entityData: ReadScaleEntityData, data = {}, errorHandler: () => void) {
  return async function readScaleThunk(dispatch: AppDispatch) {
    let weight = 0
    let readingRegisterId = null
    let readingRegisterName = null
    let isSuccess = false

    const parseEntityData = {
      entity_name: entityData.entityName,
    }

    if (entityData.entityId) {
      parseEntityData['entity_id'] = entityData.entityId
    }

    try {
      const url = buildGetUrl(`/api/iiot/devices/${scaleId}/weight`, {
        ...data,
        ...parseEntityData,
      })
      const { isSuccess: _isSuccess, result } = await (await safeFetch(url)).json()
      isSuccess = _isSuccess
      if (_isSuccess) {
        weight = +(result.weight || 0)
        readingRegisterId = result.reading_register_id
        readingRegisterName = result.reading_register_name
        dispatch(addReadingRegisterId(result.reading_register_id))

        readingRegisterId = result.reading_register_id
      }
    } catch (err) {
      console.error(err)
    }

    if (!isSuccess && errorHandler) {
      errorHandler()
    }

    return { isSuccess, weight, readingRegisterId, readingRegisterName }
  }
}

export function parseDevice(device: DeviceApi, equipmentDict?: MapData['equipmentDict']): Device {
  const options = {
    defaultData: parse({}, { fields: initialState.fields }),
    fields: initialState.fields,
    dataSetName,
    equipmentDict,
  }

  return parse(device, options)
}

function _parseDisplayTitle(device: Device) {
  return device.equipment?.title ? `${device.equipment.title} (${device.mac})` : device.mac
}
