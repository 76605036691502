import Joi from 'joi'
import { AppDispatch } from 'store'

import { valueOrTrue } from 'utils/defaultValueHelper'
import { DevLogs } from 'utils/devLogs'

import { getNormalizedMode, MODE, setIdentification, SmartFormMode } from 'reducers/smart-form/smartFormSlice'
import {
  E,
  GlobalForm,
  LineItemsFormItem,
  SmartFormDefaultAction,
  SmartFormUpdate,
  ValidationObjectType,
  SmartForm,
  HttpMethod,
} from 'reducers/smart-form/smartFormTypes'

function _validateLineItemForm<T extends E>(_form: LineItemsFormItem<T>, schema: Joi.ObjectSchema) {
  const errors = []

  const form = Object
    .keys(_form)
    .reduce((acc, id) => {
      const _validated = _validateForm(_form[id], schema)

      errors.push(..._validated.errors.map((error) => ({ ...error, isLineItem: true })))
      acc[id] = _validated.form

      return acc
    }, {})

  return { form, errors }
}

function _validateForm<T extends E>(form: GlobalForm<T>, validationSchema: Joi.ObjectSchema) {
  const test = validationSchema.validate(form, { allowUnknown: true, abortEarly: false })

  Object.keys(form).forEach((key) => {
    delete form[key].error
  })

  test.error?.details?.forEach((error) => {
    const label = error.context?.label
    const field = form[label]

    if (!field) return
    else if (!field.error) {
      field.error = []
    }

    field.error.push({
      type: error.type,
      fallbackMessage: error.message,
      context: error.context ?? {},
    })
  })

  return { isValid: !test.error, form, errors: test.error?.details || [] }
}

export function validateForm({
  globalForm,
  lineItemsForm,
}: ValidationObjectType) {
  if (globalForm.form.lineItems) {
    const { insertions, updates, deletions } = lineItemsForm.form
    const deletionsIds = Object.keys(deletions)

    const lineItemIds = Object.keys(insertions).concat(Object.keys(updates))
      .filter((id) => !deletionsIds.includes(id))

    globalForm.form.lineItems.value = lineItemIds
  }

  const validatedGlobalForm = _validateForm(globalForm.form, globalForm.validationSchema)
  const { form: validatedLineItemsFormInsertions, errors: insertionErrors } = _validateLineItemForm(
    lineItemsForm.form.insertions,
    lineItemsForm.validationSchema,
  )
  const { form: validatedLineItemsFormUpdates, errors: updateErrors } = _validateLineItemForm(
    lineItemsForm.form.updates,
    lineItemsForm.validationSchema,
  )
  const errors = [...validatedGlobalForm.errors, ...insertionErrors, ...updateErrors]
  return {
    isValid: !errors.length,
    validatedGlobalForm: validatedGlobalForm.form,
    validatedLineItemsForm: {
      ...lineItemsForm.form,
      insertions: validatedLineItemsFormInsertions,
      updates: validatedLineItemsFormUpdates,
    },
    errors,
  }
}

export function assertModeChange<
  T extends E = E,
>(mode: SmartFormMode, state: SmartForm<T>, forceActiveData?: T) {
  const normalizedMode = getNormalizedMode(mode)

  const { edit, create, delete: deleteAction } = state.config.actions

  let relatedAction: SmartFormDefaultAction = null

  switch (normalizedMode) {
  case MODE.CREATE:
    relatedAction = create
    break
  case MODE.EDIT:
    relatedAction = edit
    break
  case MODE.DELETE:
    relatedAction = deleteAction
    break
  }

  if (!relatedAction) return true

  const _activeData = forceActiveData ?? state.activeData

  const enabled = typeof relatedAction.enabled === 'function' ?
    relatedAction.enabled(_activeData) :
    relatedAction.enabled

  const authorized = typeof relatedAction.authorizeChangeToMode === 'function' ?
    relatedAction.authorizeChangeToMode(_activeData) :
    valueOrTrue(relatedAction.authorizeChangeToMode)

  return enabled && authorized
}

export function handleActionOnField<
  T extends E,
  K extends keyof T,
>(
  customAction: SmartFormUpdate<T>['customUpdate'] | undefined,
  oldValue: T[K],
  newValue: T[K],
) {
  if (!customAction) return newValue

  return customAction(oldValue, newValue)
}

export function getSmartFormState<
  M extends E = E,
  L extends E = E,
>(state: any, formKey: string) {
  return state.smartForm.forms[formKey] as SmartForm<M, L>
}

export function convertHttpMethodToApiKey(method: HttpMethod) {
  switch (method) {
  case 'post':
    return 'insertions'
  case 'put':
    return 'updates'
  case 'delete':
    return 'deletions'
  default:
    throw new Error(`Not handled method \`${method}\``)
  }
}

type EnsureResourceOptions = {
  dispatch?: AppDispatch,
  key?: string,
  getSmartPinRef: SmartForm['getSmartPinRef'] | undefined,
}

export async function ensureResource({ dispatch, key, getSmartPinRef }: EnsureResourceOptions) {
  let resource = null
  if (getSmartPinRef) {
    resource = await getSmartPinRef().resolveResource()

    if (!resource) {
      DevLogs.error('SmartForm', 'handleActionResult', 'No resource found')
    } else if (dispatch && key) {
      dispatch(setIdentification({ key, resource: resource }))
    }
  }

  return {
    resource,
    ok: !getSmartPinRef || !!resource,
  }
}

export const getCreateInitialDataKey = (key: string) => `createInitialData-${key}`

export const getCreateInitialData = ({ key }: {key: string}) => {
  const _createInitialDataKey = getCreateInitialDataKey(key)

  const rawJsonActiveData = window.sessionStorage.getItem(_createInitialDataKey)

  if (!rawJsonActiveData) return { sessionActiveData: undefined, sessionResource: undefined }

  let sessionActiveData
  let sessionResource

  try {
    ({ data: sessionActiveData, resource: sessionResource } = JSON.parse(rawJsonActiveData))
  } catch (err) {
    sessionActiveData = undefined
    sessionResource = undefined
  } finally {
    window.sessionStorage.removeItem(_createInitialDataKey)
  }

  return { sessionActiveData, sessionResource }
}

export const getFieldValue = <Entity extends E = E, Key extends keyof Entity = keyof Entity>(
  fieldKey: Key,
  activeForm: GlobalForm<Entity>,
  activeData: SmartForm<Entity>['activeData'],
  isEditable: boolean,
): Entity[Key] => {
  return isEditable ? activeForm[fieldKey]?.value : activeData?.[fieldKey]
}
