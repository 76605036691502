import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'
import { v4 as uuid } from 'uuid'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, parseError } from 'utils/safeFetch'
import { safeFetchJson } from 'utils/safeFetch'

import { Resource } from 'reducers/resources/resourcesSlice'

import {
  GET_CHECKLIST,
  GET_CHECKLIST_ITEM_TEMPLATE,
  GET_CHECKLIST_COUNT,
  GET_CHECKLIST_ITEM_TEMPLATE_COUNT,
  GET_CHECKLIST_TEMPLATE,
  GET_CHECKLIST_TEMPLATE_COUNT,
  CLEAR_CHECKLISTS,
} from './types'

const dataSetName = 'checkLists'
const dataSetNameTemplate = 'checklistTemplate'
const dataSetNameItem = 'checklistItems'
const dataSetNameItemImage = 'checklistItemImages'
const dataSetNameItemTemplate = 'checklistItemsTemplate'

const initialState = {
  dataSetName,
  fields: getFields(),
  itemFields: getItemFields(),
  itemImageFields: getItemImageFields(),
  templateFields: getTemplateFields(),
  itemTemplateFields: getItemTemplateFields(),
  checkLists: [],
  checkListsCount: 0,
  itemTemplates: [],
  itemTemplateCount: 0,
  templates: [],
  templateCount: 0,
}

export default function checkListsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_CHECKLIST_COUNT: {
    return {
      ...state,
      checkListsCount: payload,
    }
  }
  case GET_CHECKLIST: {
    return {
      ...state,
      checkLists: payload,
    }
  }
  case GET_CHECKLIST_ITEM_TEMPLATE_COUNT: {
    return {
      ...state,
      itemTemplateCount: payload,
    }
  }
  case GET_CHECKLIST_ITEM_TEMPLATE: {
    return {
      ...state,
      itemTemplates: payload,
    }
  }
  case GET_CHECKLIST_TEMPLATE: {
    return {
      ...state,
      templates: payload,
    }
  }
  case GET_CHECKLIST_TEMPLATE_COUNT: {
    return {
      ...state,
      templateCount: payload,
    }
  }
  case CLEAR_CHECKLISTS: {
    return {
      ...state,
      checkListsCount: 0,
      checkLists: [],
      itemTemplateCount: 0,
      itemTemplates: [],
      templateCount: 0,
      templates: [],
    }
  }
  default: {
    return state
  }
  }
}

type ChecklistConfigApi = {
  image_compression: 'none' | 'low' | 'medium' | 'high'
  value: (
    'allowed' | 'allowed_with_optional_comment_non_compliant' | 'allowed_with_required_comment_non_compliant' |
    'allowed_with_optional_comment_both' | 'allowed_with_required_comment_both' | 'not_allowed' |
    'not_allowed_with_optional_comment' | 'not_allowed_with_required_comment'
  )
}
export type ChecklistConfig = ApiToSlice<ChecklistConfigApi>

export type ChecklistItemImageApi = Modify<{
  checklist_item_id: string
  title: string
  file_name: string
  file_ext: string
  file_type: string
  destination: string
  comment: string
  version: number
  is_active: boolean
}, BaseEntityApi>

export type ChecklistItemImage = ApiToSlice<ChecklistItemImageApi>

export type ChecklistItemApi = Modify<{
  checklist_id: string
  title: string
  description: string
  sort_order: number
  is_done: boolean
  copied_from_template_id: string
  enable_photo: boolean
  images: ChecklistItemImageApi[]
}, BaseEntityApi>

export type ChecklistItem = ApiToSlice<Modify<ChecklistItemApi, {images: ChecklistItemImage[]}>>

// ! TODO (bzoretic): this is to remove in task #DEV-I1759
type CommentApi = Modify<{
  item_id: string
  datetime: Date
  comments: string
  shipment_id: string
  material_id: string
  template_id: string
  checklist_id: string
  consumption_id: string
  clockin_id: string
  clockout_id: string
  comments_bi: string
}, BaseEntityApi>

export type ChecklistApi = Modify<{
  is_compliant: boolean
  template_id: string
  description: string
  hyperlink: string
  title: string
  type: 'shipment' | 'reception' | 'item_step'
  config: ChecklistConfigApi
  items: ChecklistItemApi[]
  comment: CommentApi
}, BaseEntityApi>

export type Checklist = ApiToSlice<Modify<ChecklistApi, {
  config: ChecklistConfig
  items: ChecklistItem[]
}>>

export function getFields(): GetFields<ChecklistApi, Checklist, null, null, {config: { apiKey: 'config'} }> {
  return {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'isCompliant': { dataSetName, dbField: 'is_compliant', type: 'boolean' },

    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id', relationEntity: 'resources' },
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id', relationEntity: 'resources' },
    'templateId': { dataSetName, dbField: 'template_id', type: 'id', relationEntity: 'items' },

    'config': {
      dataSetName,
      dbField: 'config',
      type: 'json',
      properties: {
        'imageCompression': { dataSetName, dbField: 'image_compression' },
        'value': { dataSetName, dbField: 'value' },
      },
    },

    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'description': { dataSetName, dbField: 'description', type: 'string' },
    'hyperlink': { dataSetName, dbField: 'hyperlink', type: 'string' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'title': { dataSetName, dbField: 'title', type: 'string' },
    'type': { dataSetName, dbField: 'type', type: 'string' },

    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },

    'items': {
      parse: (checklist) => checklist.items?.map((item) => parseCheckListItem(item)) ?? [],
      type: 'array',
    },

    'comment': { parse: (checklist) => checklist.comment },
  }
}

export function parseCheckList(checkList: ChecklistApi) {
  const options = {
    defaultData: parse({}, { fields: initialState.fields }),
    fields: initialState.fields,
    dataSetName,
  }

  return parse(checkList, options)
}

export type ChecklistTemplateItemApi = Modify<{
  checklist_template_id: string
  title: string
  description: string
  sort_order: number
  enable_photo: boolean
}, BaseEntityApi>

export type ChecklistTemplateItem = ApiToSlice<ChecklistTemplateItemApi>

export type ChecklistTemplateApi = Modify<{
  type: 'shipment' | 'reception' | 'item_step'
  title: string
  description: string
  modified_uuid: string
  is_default: boolean
  config: ChecklistConfigApi
  step_id: string
  hyperlink: string
  items: ChecklistTemplateItemApi[]
}, BaseEntityApi>

export type ChecklistTemplate = ApiToSlice<Modify<
  ChecklistTemplateApi,
  {config: ChecklistConfig, items: ChecklistTemplateItem[]}
>>

export function getTemplateFields():
  GetFields<ChecklistTemplateApi, ChecklistTemplate, null, null, {config: { apiKey: 'config'} }> {
  return {
    'id': { dataSetName: dataSetNameTemplate, dbField: 'id', type: 'id' },
    'exist': { dataSetName: dataSetNameTemplate, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName: dataSetNameTemplate, dbField: 'created_date', type: 'date' },
    'modifiedDate': { dataSetName: dataSetNameTemplate, dbField: 'modified_date', type: 'date' },
    'createdBy': { dataSetName: dataSetNameTemplate, dbField: 'created_by', type: 'string' },
    'createdById': {
      dataSetName: dataSetNameTemplate,
      dbField: 'created_by_id',
      type: 'id',
      relationEntity: 'resources',
    },
    'modifiedBy': { dataSetName: dataSetNameTemplate, dbField: 'modified_by', type: 'string' },
    'modifiedById': {
      dataSetName: dataSetNameTemplate,
      dbField: 'modified_by_id',
      type: 'id',
      relationEntity: 'resources',
    },
    'type': { dataSetName: dataSetNameTemplate, dbField: 'type', type: 'string' },
    'title': { dataSetName: dataSetNameTemplate, dbField: 'title', type: 'string' },
    'description': { dataSetName: dataSetNameTemplate, dbField: 'description', type: 'string' },
    'modifiedUuid': { dataSetName: dataSetNameTemplate, dbField: 'modified_uuid', type: 'string' },
    'isDefault': { dataSetName: dataSetNameTemplate, dbField: 'is_default', type: 'boolean' },
    'config': {
      dataSetName: dataSetNameTemplate,
      dbField: 'config',
      type: 'json',
      properties: {
        'imageCompression': { dataSetName: dataSetNameTemplate, dbField: 'image_compression' },
        'value': { dataSetName: dataSetNameTemplate, dbField: 'value' },
      },
    },
    'stepId': { dataSetName: dataSetNameTemplate, dbField: 'step_id', type: 'id' },
    'hyperlink': { dataSetName: dataSetNameTemplate, dbField: 'hyperlink', type: 'string' },
    'items': {
      parse: (checklistTemplate) => checklistTemplate.items?.map((c) => parseCheckListItemTemplate(c)) ?? [],
      type: 'array',
    },
  }
}

export function getInitialCheckListTemplate() {
  return parse({}, { fields: initialState.templateFields })
}

export function parseCheckListTemplate(checkListTemplate: ChecklistTemplateApi) {
  const options = {
    defaultData: getInitialCheckListTemplate(),
    fields: initialState.templateFields,
    dataSetName: dataSetNameTemplate,
  }

  return parse(checkListTemplate, options)
}

export function getItemTemplateFields(): GetFields<ChecklistTemplateItemApi, ChecklistTemplateItem> {
  return {
    'id': { dataSetName: dataSetNameItemTemplate, dbField: 'id', type: 'id' },
    'exist': { dataSetName: dataSetNameItemTemplate, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName: dataSetNameItemTemplate, dbField: 'created_date', type: 'date' },
    'modifiedDate': {
      dataSetName: dataSetNameItemTemplate,
      dbField: 'modified_date',
      type: 'date',
    },
    'createdBy': { dataSetName: dataSetNameItemTemplate, dbField: 'created_by', type: 'string' },
    'createdById': {
      dataSetName: dataSetNameItemTemplate,
      dbField: 'created_by_id',
      type: 'id',
      relationEntity: 'resources',
    },
    'modifiedBy': { dataSetName: dataSetNameItemTemplate, dbField: 'modified_by', type: 'string' },
    'modifiedById': {
      dataSetName: dataSetNameItemTemplate,
      dbField: 'modified_by_id',
      type: 'id',
      relationEntity: 'resources',
    },
    'checklistTemplateId': {
      dataSetName: dataSetNameItemTemplate,
      dbField: 'checklist_template_id',
      type: 'id',
      relationEntity: 'checklist-templates',
    },
    'title': { dataSetName: dataSetNameItemTemplate, dbField: 'title', type: 'string' },
    'description': { dataSetName: dataSetNameItemTemplate, dbField: 'description', type: 'string' },
    'sortOrder': { dataSetName: dataSetNameItemTemplate, dbField: 'sort_order', type: 'integer' },
    'enablePhoto': { dataSetName: dataSetNameItemTemplate, dbField: 'enable_photo', type: 'boolean' },
  }
}

export function getItemFields(): GetFields<ChecklistItemApi, ChecklistItem> {
  return {
    'id': { dataSetName: dataSetNameItem, dbField: 'id', type: 'id' },
    'exist': { dataSetName: dataSetNameItem, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName: dataSetNameItem, dbField: 'created_date', type: 'date' },
    'modifiedDate': { dataSetName: dataSetNameItem, dbField: 'modified_date', type: 'date' },
    'createdBy': { dataSetName: dataSetNameItem, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName: dataSetNameItem, dbField: 'created_by_id', type: 'id', relationEntity: 'resources' },
    'modifiedBy': { dataSetName: dataSetNameItem, dbField: 'modified_by', type: 'string' },
    'modifiedById': {
      dataSetName: dataSetNameItem,
      dbField: 'modified_by_id',
      type: 'id',
      relationEntity: 'resources',
    },
    'checklistId': { dataSetName: dataSetNameItem, dbField: 'checklist_id', type: 'id', relationEntity: 'checklists' },
    'title': { dataSetName: dataSetNameItem, dbField: 'title', type: 'string' },
    'description': { dataSetName: dataSetNameItem, dbField: 'description', type: 'string' },
    'sortOrder': { dataSetName: dataSetNameItem, dbField: 'sort_order', type: 'integer' },
    'isDone': { dataSetName: dataSetNameItem, dbField: 'is_done', type: 'boolean' },
    'copiedFromTemplateId': {
      dataSetName: dataSetNameItem,
      dbField: 'copied_from_template_id',
      type: 'id',
      relationEntity: 'checklist-templates',
    },
    'enablePhoto': { dataSetName: dataSetNameItem, dbField: 'enable_photo', type: 'boolean' },
    'images': {
      parse: (checklistItem) => checklistItem.images?.map((img) => parseCheckListItemImage(img)) ?? [],
      type: 'array',
    },
  }
}

export function getItemImageFields(): GetFields<ChecklistItemImageApi, ChecklistItemImage> {
  return {
    'id': { dataSetName: dataSetNameItemImage, dbField: 'id', type: 'id' },
    'exist': { dataSetName: dataSetNameItemImage, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName: dataSetNameItemImage, dbField: 'created_date', type: 'timestamp' },
    'modifiedDate': { dataSetName: dataSetNameItemImage, dbField: 'modified_date', type: 'timestamp' },
    'createdBy': { dataSetName: dataSetNameItemImage, dbField: 'created_by', type: 'string' },
    'createdById': {
      dataSetName: dataSetNameItemImage,
      dbField: 'created_by_id',
      type: 'id',
      relationEntity: 'resources',
    },
    'modifiedBy': { dataSetName: dataSetNameItemImage, dbField: 'modified_by', type: 'string' },
    'modifiedById': {
      dataSetName: dataSetNameItemImage,
      dbField: 'modified_by_id',
      type: 'id',
      relationEntity: 'resources',
    },
    'checklistItemId': {
      dataSetName: dataSetNameItemImage,
      dbField: 'checklist_item_id',
      type: 'id',
      relationEntity: 'checklist-items',
    },
    'title': { dataSetName: dataSetNameItemImage, dbField: 'title', type: 'string' },
    'fileName': { dataSetName: dataSetNameItemImage, dbField: 'file_name', type: 'string' },
    'fileExt': { dataSetName: dataSetNameItemImage, dbField: 'file_ext', type: 'string' },
    'fileType': { dataSetName: dataSetNameItemImage, dbField: 'file_type', type: 'string' },
    'destination': { dataSetName: dataSetNameItemImage, dbField: 'destination', type: 'string' },
    'comment': { dataSetName: dataSetNameItemImage, dbField: 'comment', type: 'string' },
    'version': { dataSetName: dataSetNameItemImage, dbField: 'version', type: 'integer' },
    'isActive': { dataSetName: dataSetNameItemImage, dbField: 'is_active', type: 'boolean' },
  }
}

export function parseCheckListItem(checklistItem: ChecklistItemApi) {
  const options = {
    defaultData: parse({}, { fields: initialState.itemFields }),
    fields: initialState.itemFields,
    dataSetName: dataSetNameItem,
  }

  return parse(checklistItem, options)
}

export function parseCheckListItemImage(checklistItemImage: ChecklistItemImageApi): ChecklistItemImage {
  const options = {
    defaultData: parse({}, { fields: initialState.itemImageFields }),
    fields: initialState.itemImageFields,
    dataSetName: dataSetNameItemImage,
  }

  return parse(checklistItemImage, options)
}

export function parseCheckListItemTemplate(checkListItemTemplate: ChecklistTemplateItemApi) {
  const options = {
    defaultData: parse({}, { fields: initialState.itemTemplateFields }),
    fields: initialState.itemTemplateFields,
    dataSetName: dataSetNameItemTemplate,
  }

  return parse(checkListItemTemplate, options)
}

export function checklistItemTemplateToItem(checklistItemTemplate: ChecklistTemplateItem): Partial<ChecklistItem> {
  return {
    id: uuid(),
    title: checklistItemTemplate.title,
    description: checklistItemTemplate.description,
    sortOrder: checklistItemTemplate.sortOrder,
    isDone: false,
    copiedFromTemplateId: checklistItemTemplate.id,
    enablePhoto: checklistItemTemplate.enablePhoto,
    images: [],
  }
}

export async function fetchChecklists(data: any) {
  return async function fetchChecklistThunk(dispatch: AppDispatch) {
    const { error, checklists } = await _fetchChecklists(data)
    dispatch({ type: GET_CHECKLIST, payload: checklists, error })
    return checklists
  }
}

export async function _fetchChecklists(data?: any, ids?: string[]) {
  let checklists = []
  let error = null

  try {
    let url = '/new_api/checklists'
    if (ids?.length > 0) {
      url += `/${ids}`
    }
    const { isSuccess, result } = await safeFetchJson<ChecklistApi>(buildGetUrl(url, data))

    if (isSuccess && !isJob(result)) {
      checklists = result.map((checklist) => parseCheckList(checklist))
    }
    if (!isSuccess) error = result
  } catch (_error) {
    error = parseError(_error)
    console.error(_error)
  }

  return { checklists, error }
}

export async function _fetchChecklistItems(ids?: string[], data?: any) {
  let checklistItems = []
  let error = null

  try {
    let url = '/new_api/checklists/items'
    if (ids?.length > 0) {
      url += `/${ids}`
    }
    const { isSuccess, result } = await safeFetchJson<ChecklistItemApi>(buildGetUrl(url, data))

    if (isSuccess && !isJob(result)) {
      checklistItems = result.map((checklistItem) => parseCheckListItem(checklistItem))
    }
    if (!isSuccess) error = result
  } catch (_error) {
    error = parseError(_error)
    console.error(_error)
  }

  return { checklistItems, error }
}

export function fetchChecklistTemplates(data: any) {
  return async function fetchChecklistTemplatesThunk(dispatch: AppDispatch) {
    const checklistTemplates = await _fetchChecklistTemplates(data)
    dispatch({ type: GET_CHECKLIST_TEMPLATE, payload: checklistTemplates })
    return checklistTemplates
  }
}

export async function _fetchChecklistTemplates(data: any = {}, ids?: string[]): Promise<ChecklistTemplate[]> {
  let checklistTemplates = []
  try {
    let url = '/new_api/checklists/templates'
    if (ids?.length > 0) {
      url = `/${ids}`
    }
    const _data = { ...data, fetchItems: true }
    const { isSuccess, result } = await safeFetchJson<ChecklistTemplateApi>(buildGetUrl(url, _data))
    if (isSuccess && !isJob(result)) {
      checklistTemplates = result.map((checklistTemplate) => parseCheckListTemplate(checklistTemplate))
    }
  } catch (error) {
    console.error(error)
  }
  return checklistTemplates
}

export function fetchChecklistTemplatesCount(data: any) {
  return async function fetchChecklistTemplatesCountThunk(dispatch: AppDispatch) {
    const count = await _fetchChecklistTemplatesCount(data)
    dispatch({ type: GET_CHECKLIST_TEMPLATE_COUNT, payload: count })
    return count
  }
}

export async function _fetchChecklistTemplatesCount(data: any) {
  try {
    const result = await safeFetchJson(buildGetUrl('/new_api/checklists/templates/count', data))
    if (result.isSuccess) {
      return +result.result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return 0
}

export async function _fetchChecklistTemplateIndex(id: string, data: any) {
  let index = 0

  if (id) {
    try {
      const result = await safeFetchJson(buildGetUrl(`/new_api/checklists/templates/${id}/index`, data))
      if (result.isSuccess) {
        index = +result.result || 0
      }
    } catch (err) {
      console.error(err)
    }
  }

  return index
}

export async function _updateChecklist(id: string, updateData: Partial<ChecklistApi>, resourceId: Resource['id']) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ checklist: updateData, resourceId }),
  }

  try {
    return await safeFetchJson<ChecklistApi>(`/new_api/checklists/${id}`, requestOptions)
  } catch (error) {
    console.error(error)
    return { isSuccess: false, result: error }
  }
}

export async function _updateChecklistItem(
  id: string,
  updateData: Partial<ChecklistItemApi>,
  resourceId: Resource['id'],
) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ checklistItem: updateData, resourceId }),
  }

  try {
    return await safeFetchJson<ChecklistItemApi>(`/new_api/checklists/items/${id}`, requestOptions )
  } catch (error) {
    console.error(error)
    return { isSuccess: false, result: error }
  }
}

export function getCheckListTitle(checkList: Checklist) {
  return checkList.title
}

export function getCheckListItemTitle(checkListItem: ChecklistItem) {
  return checkListItem.title
}

export function getCheckListTemplateItemTitle(checkListTemplateItem: ChecklistTemplateItem) {
  return checkListTemplateItem.title
}

export function clearChecklist(dispatch: AppDispatch) {
  dispatch({ type: CLEAR_CHECKLISTS })
}
