export type DefaultRecord = Record<string, any>

export const getKeys = <T extends DefaultRecord>(obj: T) => Object.keys(obj) as (keyof T)[]
export const getValues = <T extends DefaultRecord>(obj: T) => Object.values(obj) as (T[keyof T])[]

export type PrefixedRecord<
  T extends DefaultRecord,
  P extends string
> = {
  [K in keyof T as K extends string ? `${P}${K}` : never]: T[K]
}

export type CamelCasePrefixedRecord<
  T extends DefaultRecord,
  P extends string
> = {
  [K in keyof T as K extends string
    ? Uncapitalize<`${Capitalize<P>}${Capitalize<K>}`>
    : never]: T[K]
}

export function prefixRecord<
  T extends Record<string, any>,
  P extends string
>(obj: T, prefix: P): { [K in keyof T as `${P}${Capitalize<string & K>}`]: T[K] } {
  const result = {} as { [K in keyof T as `${P}${Capitalize<string & K>}`]: T[K] };

  (Object.keys(obj) as Array<keyof T>).forEach((key) => {
    const capitalizedKey = `${String(key).charAt(0).toUpperCase()}${String(key).slice(1)}`
    const prefixedKey = `${prefix}${capitalizedKey}`

    result[prefixedKey] = obj[key]
  })

  return result
}

export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;

/**
 * Used like : T without U
 */
export type Without<T, U> = Pick<T, Exclude<keyof T, keyof U>>;

export type Prettify<T> = {
  [K in keyof T]: T[K]
} & {}

export type XOR<T> = Prettify<
{
  [K in keyof T]: { [P in K]: T[P] } & Partial<Record<Exclude<keyof T, K>, never>>
}[keyof T]
>;

type Primitive = string | number | boolean | null | undefined;

export type RecursiveKeys<T, Depth extends number[] = []> = Depth['length'] extends 4
  ? never
  : T extends Primitive | Date | Array<any>
  ? never
  : T extends object
  ? {
      [K in keyof T]-?: K extends string
        ? K | (RecursiveKeys<T[K], [...Depth, 1]> extends never
            ? never
            : `${K}.${RecursiveKeys<T[K], [...Depth, 1]>}`)
        : never;
    }[keyof T]
  : never;

export type PicksAndPartial<T extends Record<string, any>, K extends keyof T> = Partial<Omit<T, K>> & Pick<T, K>
