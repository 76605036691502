import React, { useRef, useState, useMemo, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react'

import { InputText } from 'primereact/inputtext'

import ErrorWrapper from 'components/alix-front/error-wrapper/ErrorWrapper'

/**
 * @typedef {Object} Props
 * @property {string} [placeholder]
 * @property {string} [autoComplete]
 * @property {string} [name]
 * @property {any} [onFocus]
 * @property {any} [onBlur]
 * @property {any} [onChange]
 * @property {number} [maxLength]
 * @property {string} [initialValue]
 * @property {boolean} [disabled]
 * @property {boolean} [autoFocus=false]
 * @property {boolean} [required=false]
 * @property {string} [id]
 * @property {boolean} [inlineMaxLength=False]
 * @property {{ active: boolean, message: string }} [error]
 * @property {boolean} [showMaxLength=false]
 */

/**
 * @param {React.PropsWithRef<Props>} props
 * @return {React.ReactElement}
 */
function SmartInputText({
  placeholder,
  autoComplete,
  name,
  onFocus,
  onBlur,
  onChange,
  maxLength,
  initialValue,
  disabled,
  autoFocus = false,
  required = false,
  id,
  error,
  inlineMaxLength = false,
  showMaxLength = false,
}, ref) {
  const inputRef = useRef(null)
  const maxLengthRef = useRef(null)
  const [value, setValue] = useState(undefined)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useImperativeHandle(ref, () => ({ value, setValue, focus: () => inputRef.current?.focus?.() }))

  const onInputChange = useCallback((ev) => {
    if (maxLength >= 0 && ev.target.value.length > maxLength) {
      return
    }

    setValue(ev.target.value)
    if (typeof onChange === 'function') {
      onChange(ev)
    }
  }, [onChange, maxLength])

  const onInputBlur = useCallback((ev) => {
    if (!isNaN(maxLength)) {
      maxLengthRef.current?.classList.add('a-hidden')
    }
    if (typeof onBlur === 'function') {
      onBlur(ev)
    }
  }, [onBlur, maxLength])

  const onInputFocus = useCallback((ev) => {
    if (!isNaN(maxLength)) {
      maxLengthRef.current?.classList.remove('a-hidden')
    }
    if (typeof onFocus === 'function') {
      onFocus(ev)
    }
  }, [onFocus, maxLength])

  const displayValue = useMemo(
    () => (typeof value === 'undefined' ? initialValue : value) || '',
    [initialValue, value],
  )
  const maxLengthString = useMemo(
    () => `(${(value || '').length}/${maxLength})`,
    [value, maxLength],
  )

  const handleAutoFocus = useCallback((nativeRef) => {
    if (autoFocus) {
      nativeRef.focus()
    }

    inputRef.current = nativeRef
  }, [autoFocus])

  const maxLengthClassNames = useMemo(() => {
    const classNames = ['a-form-element-max-length', 'a-hidden']

    if (inlineMaxLength) {
      classNames.push('a-form-element-max-length-inline')
    } else {
      classNames.push('a-form-element-top-right-section')
    }

    return classNames.join(' ')
  }, [inlineMaxLength])

  return (
    <ErrorWrapper
      active={error?.active}
      message={error?.message}
    >
      {showMaxLength ? (
        <div
          ref={maxLengthRef}
          className={maxLengthClassNames}
        >
          {maxLengthString}
        </div>
      ) : null}
      <InputText
        id={id}
        ref={handleAutoFocus}
        value={displayValue}
        onChange={onInputChange}
        placeholder={placeholder}
        onBlur={onInputBlur}
        onFocus={onInputFocus}
        disabled={disabled}
        title={displayValue}
        maxLength={maxLength}
        type="text"
        required={required}
        className={error?.active ? 'p-invalid' : undefined}
        autoComplete={autoComplete}
        name={name}
      />
    </ErrorWrapper>
  )
}

export default forwardRef(SmartInputText)
