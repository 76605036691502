import React from 'react'

import {
  getPrefixedComplexColumns as getPrefixedItemComplexColumns,
} from 'components/alix-front/legacy-smart-grid/columns/items'
import {
  getPrefixedComplexColumns as getPrefixedSalesOrderItemComplexColumns,
} from 'components/alix-front/legacy-smart-grid/columns/sales-order-item'
import {
  getPrefixedComplexColumns as getPrefixedSalesOrderComplexColumns,
} from 'components/alix-front/legacy-smart-grid/columns/sales-orders'
import { prefixFieldFromPrefix } from 'components/alix-front/legacy-smart-grid/columns/utils'
import SmartStatus from 'components/alix-front/smart-status/SmartStatus'

import { parseNumber } from 'utils/numberParser'

import {
  shipmentPlanningSalesOrderFieldPrefix,
  shipmentPlanningSalesOrderItemFieldPrefix,
  shipmentPlanningTemplateFieldPrefix,
} from 'reducers/shipment-planning/type'

export const prefixSalesOrderItem = (field: string) =>
  prefixFieldFromPrefix(field, shipmentPlanningSalesOrderItemFieldPrefix)

export const prefixSalesOrder = (field: string) =>
  prefixFieldFromPrefix(field, shipmentPlanningSalesOrderFieldPrefix)

export const prefixTemplate = (field: string) =>
  prefixFieldFromPrefix(field, shipmentPlanningTemplateFieldPrefix)

const forceIsViewHiddenFields = [
  // Cost view fields
  prefixSalesOrderItem('materialPlannedCost'),
  prefixSalesOrderItem('subcontractPlannedCost'),
  prefixSalesOrderItem('resourcePlannedCost'),
  prefixSalesOrderItem('equipmentPlannedCost'),
  prefixSalesOrderItem('materialPricing'),
  prefixSalesOrderItem('subcontractPricing'),
  prefixSalesOrderItem('resourcePricing'),
  prefixSalesOrderItem('equipmentPricing'),
  prefixSalesOrderItem('plannedUnitCost'),
  prefixSalesOrderItem('computedUnitPrice'),
  prefixSalesOrderItem('convertedComputedUnitPrice'),
  prefixSalesOrderItem('computedUnitPrice'),
  prefixSalesOrderItem('discounted'),

  // Dupplicated fields
  prefixSalesOrderItem('displayTitle'),
  prefixSalesOrderItem('partNumber'),
  prefixSalesOrderItem('templateTitle'),
  prefixSalesOrderItem('sku'),
]

const overrideVisibleFields = [
  prefixSalesOrderItem('promisedDate'),

  prefixSalesOrder('customer'),
  prefixSalesOrder('name'),

  prefixSalesOrderItem('fulfillmentStatus'),

  prefixSalesOrder('referenceNumber'),
  prefixTemplate('title'),
  prefixTemplate('sku'),

  'salesOrderItemMeasureRemainingToShip',
  'availableMeasureSummed',
  'salesOrderItemToShipStatus',
  'salesOrderToShipStatus',
]

const forceColumnOptions = {
  isColumnToggle: true,
}

const {
  columnDict: itemColumnDict,
  getComplexColumns: getItemComplexColumns,
} = getPrefixedItemComplexColumns({
  prefix: shipmentPlanningTemplateFieldPrefix,
  forceColumnOptions,
  prefixHeaders: true,
  overrideVisibleFields,
  forceIsViewHiddenFields,
})
const {
  columnDict: salesOrderColumnDict,
  getComplexColumns: getSalesOrderComplexColumns,
} = getPrefixedSalesOrderComplexColumns({
  prefix: shipmentPlanningSalesOrderFieldPrefix,
  forceColumnOptions,
  prefixHeaders: true,
  overrideVisibleFields,
  forceIsViewHiddenFields,
})
const {
  columnDict: salesOrderItemColumnDict,
  getComplexColumns: getSalesOrderItemComplexColumns,
} = getPrefixedSalesOrderItemComplexColumns(
  {
    prefix: shipmentPlanningSalesOrderItemFieldPrefix,
    forceIsViewHiddenFields,
    forceColumnOptions,
    prefixHeaders: true,
    overrideVisibleFields,
  },
  false,
)

const shipmentPlanningColumnDict = {
  'cardFormatedNumber': {
    field: `cardFormatedNumber`,
    isGlobalSearch: false,
    hidden: !overrideVisibleFields.includes('cardFormatedNumber'),
    isColumnToggle: true,
    sortable: true,
    filter: true,
  },
  'cardCurrentStep': {
    field: `cardCurrentStep`,
    isGlobalSearch: false,
    hidden: !overrideVisibleFields.includes('cardCurrentStep'),
    isColumnToggle: true,
    sortable: true,
    filter: true,
  },
  'salesOrderItemMeasureRemainingToShip': {
    field: `salesOrderItemMeasureRemainingToShip`,
    isGlobalSearch: false,
    hidden: !overrideVisibleFields.includes('salesOrderItemMeasureRemainingToShip'),
    isColumnToggle: true,
    sortable: true,
    filter: true,
  },
  'availableQuantity': {
    field: `availableQuantity`,
    isGlobalSearch: false,
    hidden: !overrideVisibleFields.includes('availableQuantity'),
    isColumnToggle: true,
    sortable: false,
    filter: false,
  },
  'availableMeasureSummed': {
    field: `availableMeasureSummed`,
    isGlobalSearch: false,
    hidden: !overrideVisibleFields.includes('availableMeasureSummed'),
    isColumnToggle: true,
    sortable: true,
    filter: true,
  },
  'readyToShipStatus': {
    field: `readyToShipStatus`,
    isGlobalSearch: false,
    hidden: !overrideVisibleFields.includes('readyToShipStatus'),
    isColumnToggle: true,
    sortable: false,
    filter: false,
  },
  'salesOrderItemToShipStatus': {
    field: `salesOrderItemToShipStatus`,
    isGlobalSearch: false,
    hidden: !overrideVisibleFields.includes('salesOrderItemToShipStatus'),
    isColumnToggle: true,
    sortable: true,
    filter: true,
  },
  'salesOrderToShipStatus': {
    field: `salesOrderToShipStatus`,
    isGlobalSearch: false,
    hidden: !overrideVisibleFields.includes('salesOrderToShipStatus'),
    isColumnToggle: true,
    sortable: true,
    filter: true,
  },
}

export const columnDict ={
  ...itemColumnDict,
  ...salesOrderColumnDict,
  ...salesOrderItemColumnDict,
  ...shipmentPlanningColumnDict,
}

const statusColumnTemplate = (value) => {
  return (
    <SmartStatus
      className="a-smart-grid-icon-info"
      status={value}
      dictionaryKey="shipmentPlanning"
      type="readyToShipStatus"
    />
  )
}

const getFulfillmentStatusColumn = (t, field) => {
  return {
    type: 'multiselect',
    options: [
      {
        label: t('shipmentPlanning:readyToShipStatus.full'),
        value: 'full',
      },
      {
        label: t('shipmentPlanning:readyToShipStatus.noStock'),
        value: 'noStock',
      },
      {
        label: t('shipmentPlanning:readyToShipStatus.partial'),
        value: 'partial',
      },
    ],
    itemTemplate: (option) => statusColumnTemplate(option.value),
  }
}

export const getComplexColumns = (t, options) => {
  const _measure = (value, unit, { measureDigits, culture }) => {
    const parsedNumber = parseNumber(value, measureDigits, culture)

    return `${parsedNumber} ${unit}`
  }

  const itemComplexColumns = getItemComplexColumns(t, options)
  const salesOrderItemComplexColumns = getSalesOrderItemComplexColumns(t, options)
  const salesOrderComplexColumns = getSalesOrderComplexColumns(t, options)

  return {
    ...itemComplexColumns,
    ...salesOrderItemComplexColumns,
    ...salesOrderComplexColumns,
    'cardFormatedNumber': {
      ...columnDict['cardFormatedNumber'],
      header: t('cards:card.fields.itemNumber'),
      template: (props, _1, _2, { iframedFullcard }) => {
        return (
          <div
            className="a-smart-grid-default-template a-clickable-link"
            onClick={() => iframedFullcard?.open('card', props[prefixSalesOrderItem('manufacturingOrderId')])}
          >
            {props['cardFormatedNumber']}
          </div>
        )
      },
    },
    'cardCurrentStep': {
      ...columnDict['cardCurrentStep'],
      header: t('cards:card.fields.step'),
    },
    'salesOrderItemMeasureRemainingToShip': {
      ...columnDict['salesOrderItemMeasureRemainingToShip'],
      header: t('shipmentPlanning:shipmentPlanning.fields.salesOrderItemMeasureRemainingToShip'),
      parseValue: (props) => _measure(
        props['salesOrderItemMeasureRemainingToShip'],
        props[`${shipmentPlanningSalesOrderItemFieldPrefix}unit`],
        { measureDigits: options.measureDigits, culture: options.culture },
      ),
    },
    'availableQuantity': {
      ...columnDict['availableQuantity'],
      header: t('shipmentPlanning:shipmentPlanning.fields.availableQuantity'),
      parseValue: (props) => _measure(
        props['availableQuantity'],
        props[`${shipmentPlanningSalesOrderItemFieldPrefix}unit`],
        { measureDigits: options.measureDigits, culture: options.culture },
      ),
    },
    'availableMeasureSummed': {
      ...columnDict['availableMeasureSummed'],
      header: t('shipmentPlanning:shipmentPlanning.fields.availableMeasureSummed'),
      template: (props, _1, _2, { iframedFullcard }) => {
        const value = _measure(
          props['availableMeasureSummed'],
          props[`${shipmentPlanningSalesOrderItemFieldPrefix}unit`],
          { measureDigits: options.measureDigits, culture: options.culture },
        )
        if (props[prefixTemplate('title')]) {
          return (
            <a
              href={`/inventories/?globalFilter=${encodeURIComponent(props[prefixTemplate('title')])}`}
              className="a-smart-grid-default-template a-clickable-link"
              target='_blank'
              rel="noreferrer"
            >
              {value}
            </a>
          )
        } else if (props[prefixSalesOrderItem('manufacturingOrderId')]) {
          return (
            <a
              className="a-smart-grid-default-template a-clickable-link"
              onClick={() => iframedFullcard?.open('card', props[prefixSalesOrderItem('manufacturingOrderId')])}
            >
              {value}
            </a>
          )
        }
      },
    },
    'readyToShipStatus': {
      ...columnDict['readyToShipStatus'],
      'header': t('shipmentPlanning:shipmentPlanning.fields.readyToShipStatus.cumul'),
      'template': (props) => {
        return (
          <SmartStatus
            className="a-smart-grid-icon-info"
            status={props['readyToShipStatus']}
            dictionaryKey="shipmentPlanning"
            type="readyToShipStatus"
          />
        )
      },
    },
    'salesOrderItemToShipStatus': {
      ...columnDict['salesOrderItemToShipStatus'],
      header: t('salesOrders:itemsSmartGridHeaderWithPrefix', {
        header: t('shipmentPlanning:shipmentPlanning.fields.readyToShipStatus.default'),
      }),
      template: (props) => {
        return (
          <SmartStatus
            className="a-smart-grid-icon-info"
            status={props['salesOrderItemToShipStatus']}
            dictionaryKey="shipmentPlanning"
            type="readyToShipStatus"
          />
        )
      },
      filterElement: getFulfillmentStatusColumn(t, 'salesOrderItemToShipStatus'),
    },
    'salesOrderToShipStatus': {
      ...columnDict['salesOrderToShipStatus'],
      header: t('salesOrders:smartGridHeaderWithPrefix', {
        header: t('shipmentPlanning:shipmentPlanning.fields.readyToShipStatus.default'),
      }),
      template: (props) => {
        return (
          <SmartStatus
            className="a-smart-grid-icon-info"
            status={props['salesOrderToShipStatus']}
            dictionaryKey="shipmentPlanning"
            type="readyToShipStatus"
          />
        )
      },
      filterElement: getFulfillmentStatusColumn(t, 'salesOrderToShipStatus'),
    },
    // override sales order fields
    [prefixSalesOrder('name')]: {
      ...salesOrderComplexColumns[prefixSalesOrder('name')],
      header: t('salesOrders:salesOrder.fields.salesOrder'),
      template: (props) => {
        return (
          <a
            className='a-smart-grid-default-template a-clickable-link'
            href={`/sales-orders/${props[prefixSalesOrder('id')]}`}
            target='_blank'
            rel="noreferrer"
          >
            {props[prefixSalesOrder('name')]}
          </a>
        )
      },
    },
    [prefixSalesOrder('referenceNumber')]: {
      ...salesOrderComplexColumns[prefixSalesOrderItem('referenceNumber')],
      header: t('salesOrders:salesOrder.fields.referenceNumber'),
    },
    [prefixSalesOrder('customer')]: {
      ...salesOrderComplexColumns[prefixSalesOrderItem('customer')],
      header: t('salesOrders:salesOrder.fields.customer'),
      template: (props) => {
        return (
          <a
            className='a-smart-grid-default-template a-clickable-link'
            href={`/customers/${props[prefixSalesOrder('customerId')]}`}
            target='_blank'
            rel="noreferrer"
          >
            {props[prefixSalesOrder('customer')]}
          </a>
        )
      },
    },
    [prefixSalesOrder('projectName')]: {
      ...salesOrderComplexColumns[prefixSalesOrderItem('projectName')],
      header: t('salesOrders:salesOrder.fields.project'),
    },

    // override item fields
    [prefixSalesOrderItem('promisedDate')]: {
      ...salesOrderItemComplexColumns[prefixSalesOrderItem('promisedDate')],
      header: t('salesOrders:salesOrder.item.promisedDate'),
    },

    // override item fields
    [prefixTemplate('title')]: {
      ...itemComplexColumns[prefixTemplate('title')],
      header: t('items:item.fields.item'),
      template: (props, _1, _2, { iframedFullcard }) => {
        return (
          <div
            className="a-smart-grid-default-template a-clickable-link"
            onClick={() => iframedFullcard?.open('item', props[prefixTemplate('id')])}
          >
            {props[prefixTemplate('title')]}
          </div>
        )
      },
    },
    [prefixTemplate('sku')]: {
      ...itemComplexColumns[prefixTemplate('sku')],
      header: t('items:item.fields.sku.label'),
    },
  }
}
