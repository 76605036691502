import React from 'react'

import { TFunction } from 'i18next'
import { FieldType, GetFields, Modify } from 'types/slices'
import { DefaultRecord } from 'types/utils'

import { ColumnProps } from 'primereact/column'

import { SmartGridInput } from 'components/alix-front/smart-grid-input/SmartGridInput'
import SmartStatus from 'components/alix-front/smart-status/SmartStatus'

import { DevLogs } from 'utils/devLogs'
import { EntityType, getEntityObject, getFieldTranslationKey } from 'utils/entities'
import { getFirstString } from 'utils/getFirstOfType'
import { getNameKeyOfEntity } from 'utils/history'
import { Digit, parseCurrency, parseNumber } from 'utils/numberParser'
import { SafeFetchResponse } from 'utils/safeFetch'
import { toCamel } from 'utils/stringUtils'
import { convertFromDollarPerBase, formatMeasure } from 'utils/unitConverter'

import DateColumn from './DateColumn'

export type ColumnDict<T extends DefaultRecord> = Partial<Record<keyof T, SmartGridColumnType<T>>>

export type SelectionAction = {
  key: string
  title?: string
  titleKey?: string
  isEnabled?: boolean
  disabledTooltip?: string
  disabledTooltipKey?: string
  disabledTooltipOptions?: Record<string, any>
  onClick?: () => Promise<{ response: SafeFetchResponse }>

  // more action keys
  isMoreAction?: boolean
  faIcon?: string
  actionOrder?: number
  label?: string
  className?: string
  tooltipTarget?: string
  command?: () => Promise<{ response: SafeFetchResponse }>
}

export type HandlerOptions = {
  useEntityTranslation?: boolean
  baseCurrency?: {
    code: string
    symbol: string
  }
  measureDigits?: Digit
  culture?: string
  primaryLanguage?: string
  secondaryLanguage?: string
}

type MeasureTemplateOptions = {
  value: string | number,
  measureDigits?: Digit,
  culture?: string,
   unit: string
}
export function measureTemplate({ value, measureDigits, culture, unit }: MeasureTemplateOptions) {
  const isNumber = !isNaN(+value)
  const parsedNumber = parseNumber(isNumber ? formatMeasure(+value, unit) : value, measureDigits, culture)

  return `${parsedNumber} ${unit}`
}

function _handleEnumType<EntityApi extends Record<string, any>, Entity extends Record<string, any>>(
  field: FieldType<EntityApi, Entity>,
  column: SmartGridColumnType,
  t: TFunction,
) {
  const values = field.values ?? []

  return {
    filter: true,
    ...column,
    filterElement: {
      type: 'multiselect',
      options: column.filterElement?.options ?? values.map((value) => ({
        label: _translationToCamel<EntityApi, Entity>(t, field, value),
        value,
      })),
    },
    parseValue: (props: Entity) => _translationToCamel<EntityApi, Entity>(t, field, props[column.field]),
  }
}

export function _translationToCamel<EntityApi extends Record<string, any>, Entity extends Record<string, any>>(
  t: TFunction,
  field: FieldType<EntityApi, Entity>,
  value: string,
) {
  const defaultPath = (fieldKey: string) => (
    typeof field.translationPath === 'function' ?
      field.translationPath(fieldKey) :
      `${field.translationPath}.${fieldKey}`
  )
  return t([toCamel(value, field), value].map((v) => defaultPath(v)))
}

function _handleLanguageType<Entity extends Record<string, any>>(column: SmartGridColumnType, t: TFunction) {
  return {
    filter: true,
    ...column,
    filterElement: {
      type: 'multiselect',
      options: [
        { label: t('common:languages.en'), value: 'en-CA' },
        { label: t('common:languages.fr'), value: 'fr-CA' },
      ],
    },
    parseValue: (props: Entity) => t(`common:languages.${props.language?.split('-')[0] ?? 'fr'}`),
  }
}

function _handleStatusType<EntityApi extends Record<string, any>, Entity extends Record<string, any>>(
  field: FieldType<EntityApi, Entity>,
  column: SmartGridColumnType,
  t: TFunction,
) {
  const values = field.values ?? []

  const options = values.map((value) => ({
    label: _translationToCamel<EntityApi, Entity>(t, field, value),
    value,
  }))

  const getStatus = (value: string) => {
    const override = field.translationPath ?
      { title: _translationToCamel<EntityApi, Entity>(t, field, value) } :
      undefined

    return (
      <SmartStatus
        status={value}
        dictionaryKey={field.dictionaryKey}
        type={field.dictionaryType}
        override={override}
      />
    )
  }

  return {
    filter: true,
    ...column,
    filterElement: {
      type: 'multiselect',
      options,
      itemTemplate: (option: { value: string }) => {
        return getStatus(option.value)
      },
    },
    parseValue: (props: Entity) => _translationToCamel<EntityApi, Entity>(t, field, props[column.field]),
    template: (props: Entity) => {
      return getStatus(props[column.field])
    },
  }
}

function _handleBooleanType<Entity extends Record<string, any>>(column: SmartGridColumnType, t: TFunction) {
  return {
    filter: true,
    ...column,
    filterElement: {
      type: 'multiselect',
      options: [
        { label: t('common:activated'), value: 'true' },
        { label: t('common:deactivated'), value: 'false' },
      ],
    },
    parseValue: (props: Entity) => t(`common:${props[column.field] ? 'activated' : 'deactivated'}`),
  }
}

function _handleMeasureType<EntityApi extends Record<string, any>, Entity extends Record<string, any>>(
  column: SmartGridColumnType,
  field: FieldType<EntityApi, Entity>,
  options: HandlerOptions,
  entity: EntityType,
) {
  return {
    ...column,
    className: column.className ?? 'a-right',
    parseValue: (props: Entity) => {
      if (field.customFormattedValue) {
        return field.customFormattedValue(props[column.field], options, props)
      }

      const unit = options.useEntityTranslation ? entity.getUnit(props, column.field, field) ?? props.unit : props.unit

      return measureTemplate({
        value: props[column.field],
        measureDigits: options.measureDigits,
        culture: options.culture,
        unit: unit,
      })
    },
  }
}

function _handleDateType<Entity extends Record<string, any>>(column: SmartGridColumnType, type: FieldType['type']) {
  return {
    ...column,
    template: (props: Entity) => (
      <DateColumn
        date={props[column.field]}
        hideHours={column.hideHours}
      />
    ),
  }
}

function _handleTextType<EntityApi extends Record<string, any>, Entity extends Record<string, any>>(
  column: SmartGridColumnType,
  field: FieldType<EntityApi, Entity>,
  entity: EntityType,
): Partial<SmartGridColumnType> {
  if (!column.editable) return {}

  return {
    template: (props: Entity) => (
      <SmartGridInput<EntityApi, Entity, string>
        column={column}
        entity={entity}
        data={props}
        field={field}
        type="text"
      />
    ),
    style: {
      width: '20rem',
    },
  }
}

function _handleCurrencyType<
  EntityApi extends Record<string, any>,
  Entity extends Record<string, any>
>(
  column: SmartGridColumnType,
  entity: EntityType,
  options: HandlerOptions,
  field: FieldType<EntityApi, Entity>,
) {
  return {
    ...column,
    className: column.className ?? 'a-right',
    parseValue: (props: Entity) => {
      const value = props[column.field]

      const dimension = entity.getDimension(props, column.field, field)
      const unit = entity.getUnit(props, column.field, field)

      if (!dimension || !unit) {
        DevLogs.warn(
          `Column \`${column.field}\` from entity \`${entity.translationFile}\` is missing dimension or unit`,
        )

        return value
      }

      return parseCurrency(
        +convertFromDollarPerBase(dimension, +value || 0, unit),
        options.baseCurrency.code,
        options.baseCurrency.symbol,
        options.measureDigits,
        options.culture,
      )
    },
  }
}

function _handleEntityType<Entity extends Record<string, any>>(column: SmartGridColumnType, t: TFunction) {
  return {
    ...column,
    parseValue: (props: Entity) => {
      const entityName = props[column.field]

      const entity = getEntityObject(entityName)

      if (!entity) {
        DevLogs.error(`Entity \`${entityName}\` not found`)

        return null
      }

      return t(getNameKeyOfEntity(entityName))
    },
  }
}

function headerWithOptions<EntityApi extends Record<string, any>, Entity extends Record<string, any>>(
  field: FieldType<EntityApi, Entity>,
  options: HandlerOptions,
) {
  let headerOptions: Record<string, any> = {}
  Object.keys(field.headerOptions ?? {}).map((optionKey) => {
    headerOptions = {
      ...headerOptions,
      [optionKey]: options[field.headerOptions?.[optionKey]] || field.headerOptions?.[optionKey],
    }
  })
  return headerOptions
}

type SmartGridColumnTemplateOptions = { iframedFullcard: any }
export type SmartGridColumnType<
  Entity extends DefaultRecord = DefaultRecord,
> = Modify<ColumnProps, {
  field: keyof Entity
  hideHours?: boolean
  translationPath?: string
  translationKey?: string
  parseValue?: (props: any) => string | null
  template?: (props: any, value: any, index: number, options?: SmartGridColumnTemplateOptions) => React.ReactNode
  filterElement?: {
    type: string
    options?: { label: string, value: string }[]
  }
  isColumnToggle?: boolean
  isGlobalSearch?: boolean
  parseExcel?: (props: any) => string | null
  /**
   * If the column is editable inline, default to false.
   */
  editable?: boolean
  isDefaultSort?: boolean
  defaultSortOrder?: -1 | 1

  /**
   * `getPrefixedColumnsHelpers` related options
   */

  /**
   * If the column header should be prefixed when used by another entity.
   * Default to true.
   */
  addPrefixToHeader?: boolean
}>

type MapColumnOptions<EntityApi extends Record<string, any>, Entity extends Record<string, any>> = {
  t: TFunction
  fields: GetFields<EntityApi, Entity>
  entity: EntityType
  defaultHeaderPath?: string
  handlerOptions?: HandlerOptions
  exists?: (key: string | string[]) => boolean
}
export function mapColumns<
  EntityApi extends Record<string, any> = Record<string, any>,
  Entity extends Record<string, any> = Record<string, any>
>(
  columns: SmartGridColumnType[],
  options: MapColumnOptions<EntityApi, Entity>,
): SmartGridColumnType[] {
  const handlerOptions = options.handlerOptions || {}
  return columns.map((column) => {
    const field = options.fields[column.field]

    if (field) {
      const { type } = field

      switch (type) {
      case 'enum': {
        column = {
          ...column,
          ..._handleEnumType<EntityApi, Entity>(field, column, options.t),
        }
        break
      }
      case 'boolean': {
        column = {
          ...column,
          ..._handleBooleanType<Entity>(column, options.t),
        }
        break
      }
      case 'date':
      case 'timestamp': {
        column = {
          ...column,
          ..._handleDateType<Entity>(column, field.type),
        }

        break
      }
      case 'currency': {
        column = {
          ...column,
          ..._handleCurrencyType<EntityApi, Entity>(column, options.entity, handlerOptions, field),
        }

        break
      }
      case 'measure': {
        column = {
          ...column,
          ..._handleMeasureType<EntityApi, Entity>(column, field, handlerOptions, options.entity),
        }
        break
      }
      case 'language': {
        column = {
          ...column,
          ..._handleLanguageType<Entity>(column, options.t),
        }
        break
      }
      case 'status': {
        column = {
          ...column,
          ..._handleStatusType<EntityApi, Entity>(field, column, options.t),
        }
        break
      }
      case 'entity': {
        column = {
          ...column,
          ..._handleEntityType<Entity>(column, options.t),
        }
        break
      }
      default:
        column = {
          ...column,
          ..._handleTextType<EntityApi, Entity>(column, field, options.entity),
        }

        break
      }
    } else {
      DevLogs.error(
        'mapColumns',
        `The field \`${column.field}\` was not found in the GetFields().`,
        column,
        options.fields,
      )
    }

    const translationFieldKey = getFirstString(column.translationKey, field?.baseKey, column.field)

    let header: string | string[] = ''
    let alreadyTranslated = false

    if (column.translationPath) {
      header = column.translationPath
    } else if (field?.customFieldTranslationKey) {
      header = field.customFieldTranslationKey(options.t, options.handlerOptions)
      alreadyTranslated = true
    } else if (handlerOptions.useEntityTranslation) {
      header = getFieldTranslationKey(options.entity, translationFieldKey, options.handlerOptions)
    } else if (options.defaultHeaderPath) {
      header = `${options.defaultHeaderPath}.${translationFieldKey}`
    }

    if (options.exists && !options.exists(header)) {
      DevLogs.error(`Missing translation for field \`${column.field}\`.\n Translation key: \`${header}\``)
    }

    let headerOptions = {}
    if (field?.headerOptions) {
      headerOptions = headerWithOptions(field, handlerOptions)
    }

    return {
      ...column,
      header: alreadyTranslated ?
        header :
        options.t(header, headerOptions),
    }
  })
}

export function prefixItem(item: Record<string, any>, prefix: string) {
  return Object.keys(item).reduce(
    (acc, key) => {
      const newKey = `${prefix}.${key}`

      return {
        ...acc,
        [newKey]: item[key],
      }
    },
    {},
  )
}

// ? Source: https://stackoverflow.com/a/52171480
export const hash = (string: string, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed
  let h2 = 0x41c6ce57 ^ seed
  for (let i = 0, ch; i < string.length; i++) {
    ch = string.charCodeAt(i)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }

  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909)

  return 4294967296 * (2097151 & h2) + (h1 >>> 0)
}

export const mapValueToActionDependencyFields = (
  value: Record<string, any>,
  actionDependencyFields: string[],
): Record<string, any> => {
  return actionDependencyFields.reduce((acc, field) => ({ ...acc, [field]: value[field] }), {})
}

/**
 * Sort a columnDict based on an array of keys.
 * The goal here is to make it so that the fields in the columnDict are in the same order as the keys in the array,
 * ignoring the keys not present in the keys array.
 */
export const sortColumnDictFromKeys = (columnDict: DefaultRecord, keys: string[]) => {
  return Object
    .keys(columnDict)
    .sort((a, b) => {
      const indexA = keys.indexOf(a)
      const indexB = keys.indexOf(b)

      // If both are not in the keys, we do not sort them
      if (indexA === -1 && indexB === -1) {
        return 0
      } else if (indexA === -1) {
        return 1
      } else if (indexB === -1) {
        return -1
      } else {
        return indexA - indexB
      }
    })
    .reduce((acc, key) => ({ ...acc, [key]: columnDict[key] }), {})
}
