import { debounceByKey } from './debounce'

export const rows = 36

export function debounceInitialFetch(uuid, setIsFetching) {
  return debounceByKey(
    `smart-fetch-${uuid}`,
    ({ setLazyItems, lazyData, options = {} }) => {
      setIsFetching?.(true)

      return fetch(
        lazyData,
        options.fetchData || lazyData.fetchData,
        options.mapData || lazyData.mapData,
        options.value,
        options.filterFieldKeys,
      ).then(({ index, lazyItems }) => {
        setLazyItems((state) => ({ ...state, items: lazyItems, index }))
        setIsFetching?.(false)

        return lazyItems
      })
    },
    500,
  )
}

export function fetch(lazyData, fetchData = {}, mapData = {}, value, filterFieldKeys) {
  return Promise.all([
    lazyData.fetchIndex?.(value, { ...fetchData, orderBy: JSON.stringify(lazyData.orderByList) }),
    lazyData.fetchCount(fetchData, mapData),
  ]).then(([index, count = 0]) => {
    const offset = typeof lazyData.getOffset === 'function' ?
      lazyData.getOffset(count) :
      Math.min(index > -1 ? index : 0, Math.max(count - 32, 0))
    const topValue = typeof lazyData.getTopValue === 'function' ?
      lazyData.getTopValue() :
      rows
    return lazyData.fetch(
      { ...fetchData, offset, topValue, orderByList: JSON.stringify(lazyData.orderByList) },
      mapData,
    ).then((newItems) => {
      if (isNaN(count)) {
        count = newItems.length
      }
      return {
        fetched: newItems,
        index,
        lazyItems: _loadChunk(newItems, offset, _initItems(count), filterFieldKeys),
        count,
      }
    })
  })
}

export function debounceLazyLoad(uuid, setIsFetching) {
  return debounceByKey(
    `smart-fetch-${uuid}`,
    ({ setLazyItems, ev, lazyData, filterFieldKeys }) => {
      const first = Math.max(ev.first, 0)
      const fetchData = lazyData.fetchData || {}
      const mapData = lazyData.mapData || {}
      lazyData.fetch(
        { offset: first, topValue: rows, ...fetchData, orderByList: JSON.stringify(lazyData.orderByList) },
        mapData,
      ).then((newItems) => {
        setLazyItems((lazyItems) => ({
          ...lazyItems,
          items: _loadChunk(newItems, first, lazyItems.items, filterFieldKeys),
        }))
        setIsFetching?.(false)
      })
    },
    500,
  )
}

const _initItems = (count = 0) => Array.from({ length: count }).map((_, index) => ({ id: `_temp_${index}` }))

const _loadChunk = (chunk, first, value, filterFieldKeys) => {
  for (let i = 0; i < chunk.length; i++) {
    value[i + first] = { ...chunk[i] }
  }

  return value.map((obj) => {
    const _obj = { ...obj }
    if (filterFieldKeys?.length) {
      _obj._filter = filterFieldKeys.map((key) => _obj[key] ?? '').join(' | ')
    }
    return _obj
  })
}
