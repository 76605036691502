export function valueOrDefault(value, defaultValue) {
  return value === undefined ? defaultValue : value
}

export function valueOrString(value) {
  return stringIfDefined(value) ?? ''
}

export function isNullOrEmpty(value) {
  return value === undefined || value === null || value === '' || value === 'null'
}

export function valueOrTrue(value) {
  return typeof value === 'boolean' ? value : true
}

export function valueOrFalse(value) {
  return typeof value === 'boolean' ? value : false
}

export function defaultPromise(promiseCb, isToExecute = false, defaultReturn) {
  if (isToExecute) {
    return promiseCb()
  } else {
    return Promise.resolve(defaultReturn)
  }
}

export const safeDivide = (numerator: number, denominator: number, defaultReturn: number = 0) => {
  if (denominator === 0) {
    return defaultReturn
  }

  return numerator / denominator
}

export const stringIfDefined = (value: string | number) => {
  if (isNullOrEmpty(value)) {
    return undefined
  }

  return String(value)
}

export const numberIfDefined = (value: string | number) => {
  if (isNullOrEmpty(value)) {
    return undefined
  }

  const parsedValue = Number(value)

  if (isNaN(parsedValue)) {
    return undefined
  }

  return parsedValue
}

export const toDateIfDefined = (value: Date | string | number | undefined | null, fallback = null) => {
  if (isNullOrEmpty(value)) {
    return fallback
  }

  return value instanceof Date ?
    value :
    new Date(value)
}

/**
 * Retrieves a property value from an object with fallback options.
 * @param obj - The object to retrieve the property from.
 * @param aKey - The first key to check for the property.
 * @param bKey - The second key to check for the property.
 * @param isPriorityOnAKey - Determines whether the first key has priority over the second key.
 * @returns The value of the property, or null if the object is falsy.
 */
export const getPropertyWithFallback = <
  ObjType extends Record<string, any>,
  AKeyType extends keyof ObjType,
  BKeyType extends keyof ObjType
>(obj: ObjType, aKey: AKeyType, bKey: BKeyType, isPriorityOnAKey: boolean): ObjType[AKeyType] | ObjType[BKeyType] => {
  if (!obj) return null
  return isPriorityOnAKey ?
    obj[aKey] || obj[bKey] :
    obj[bKey] || obj[aKey]
}

export const getFirstOrValue = <T>(data: T | T[]) => {
  if (Array.isArray(data)) {
    return data[0]
  }

  return data
}
