import { AppDispatch } from 'store'

import { buildGetUrl } from 'utils/api'
import { valueOrDefault } from 'utils/defaultValueHelper'
import { EntityType } from 'utils/entities'
import { isJob, safeFetch, safeFetchJson } from 'utils/safeFetch'

import {
  Reception,
  ReceptionApi,
  ReceptionMapData,
  WeighType,
} from 'reducers/receptions/receptionType'
import { getFields, initialState, parseReception } from 'reducers/receptions/shared'
import { Resource } from 'reducers/resources/resourcesSlice'

import {
  GET_RECEPTIONS,
  GET_RECEPTIONS_COUNT,
  DELETE_RECEPTIONS,
  UPDATE_RECEPTION,
} from './types'

export default function receptionsReducer(state = initialState, action: any) {
  const { payload } = action
  switch (action.type) {
  case GET_RECEPTIONS: {
    return {
      ...state,
      receptions: payload,
    }
  }
  case GET_RECEPTIONS_COUNT: {
    return {
      ...state,
      receptionsCount: payload,
    }
  }
  case UPDATE_RECEPTION: {
    return {
      ...state,
      receptions: state.receptions.map((reception) => reception.id === payload.id ? payload : reception),
    }
  }
  default: {
    return state
  }
  }
}

export async function fetchReceptionsByIds(
  ids: Reception['id'][],
  ReceptionMapData?: ReceptionMapData,
  data?: Record<string, any>,
) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<ReceptionApi>(
    buildGetUrl(`/new_api/receptions/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((reception) => parseReception(reception, ReceptionMapData)) :
    []
}

export function fetchReceptions(
  data: Record<string, any>,
  ReceptionMapData?: ReceptionMapData,
) {
  return async function fetchReceptionsThunk(dispatch: AppDispatch) {
    const receptions = await _fetchReceptions(data, ReceptionMapData)
    dispatch({ type: GET_RECEPTIONS, payload: receptions })
    return receptions
  }
}

export async function _fetchReceptions(
  data: Record<string, any>,
  ReceptionMapData?: ReceptionMapData,
) {
  let receptions = []

  try {
    const { isSuccess, result } = await safeFetchJson<ReceptionApi>(
      buildGetUrl('/new_api/receptions', data),
    )
    if (isSuccess && !isJob(result)) {
      receptions = result.map((reception) =>
        parseReception(reception, ReceptionMapData),
      )
    }
  } catch (err) {
    console.error(err)
  }

  return receptions
}

export function fetchReceptionsCount(data?: Record<string, any>) {
  return async function fetchReceptionsCountThunk(dispatch: AppDispatch) {
    const count = await _fetchReceptionsCount(data)
    dispatch({ type: GET_RECEPTIONS_COUNT, payload: count })
    return count
  }
}

export async function _fetchReceptionsCount(data?: Record<string, any>) {
  let count = 0
  try {
    const result = await (
      await safeFetch(buildGetUrl('/new_api/receptions/count', data))
    ).json()
    if (result.isSuccess) {
      count = +result.result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export async function fetchNextNumber() {
  let nextNumber = ''

  try {
    const result = await (
      await safeFetch('/new_api/receptions/next-number')
    ).json()
    if (result.isSuccess) {
      nextNumber = result.result
    }
  } catch (err) {
    console.error(err)
  }

  return nextNumber
}

export async function _updateReceptionChecklist(id: string, checklistTemplateId: string, resourceId: Resource['id']) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ checklist_template_id: checklistTemplateId, resource_id: resourceId }),
  }

  try {
    return safeFetchJson<ReceptionApi>(`/new_api/receptions/${id}/checklist`, requestOptions)
  } catch (error) {
    console.error(error)
    return { isSuccess: false, result: error }
  }
}

export function updateReception(id: string, updateData: Partial<ReceptionApi>) {
  return async function updateReceptionThunk(dispatch) {
    const result = await _updateReception(id, updateData)
    if (result.isSuccess) {
      dispatch({ type: UPDATE_RECEPTION, payload: result.reception, error: result.error })
    } else {
      dispatch({ type: UPDATE_RECEPTION, error: result.error })
    }
  }
}

export async function _updateReception(id: string, updateData: Partial<ReceptionApi>) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ reception: updateData }),
  }

  try {
    const { isSuccess, result } = await safeFetchJson<ReceptionApi>(
      `/new_api/receptions/${id}`, requestOptions,
    )
    const reception = isSuccess && !isJob<ReceptionApi>(result) ?
      parseReception(result[0]) :
      null
    const error = !isSuccess ? result : null
    return { isSuccess, reception, error }
  } catch (error) {
    console.error(error)
    return { isSuccess: false, error }
  }
}

type UpdateReceptionReadingRegister = { id: string } | {
  measure: number
  date_time: Date
  resource_id: number
}
export async function _updateReceptionReadingRegister(
  id: string,
  type: WeighType,
  readingRegister: UpdateReceptionReadingRegister,
  resourceId: Resource['id'],
) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type, readingRegister, resource_id: resourceId }),
  }

  try {
    return safeFetchJson<ReceptionApi>(
      `/new_api/receptions/${id}/reading-register`, requestOptions,
    )
  } catch (error) {
    console.error(error)
    return { isSuccess: false, result: error }
  }
}

export function deleteReceptions(ids: string[], resourceId: Resource['id']) {
  return async function deleteReceptionsThunk(dispatch: AppDispatch) {
    try {
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ resource_id: resourceId }),
      }
      const result = await (
        await safeFetch(`/new_api/receptions/${ids}`, requestOptions)
      ).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_RECEPTIONS, payload: !!result.isSuccess, error })
      return result
    } catch (error) {
      dispatch({ type: DELETE_RECEPTIONS, payload: false, error })
      console.error(error)
    }
  }
}

export function getReceptionTitle(reception: Reception) {
  return reception.name
}

export const ReceptionEntity: EntityType<Reception> = {
  translationFile: 'receptions',
  coreRelationName: 'reception',
  getFields: getFields,
  namespace: (entity, field, options = {}) => {
    const fieldState = valueOrDefault(options.fieldState, 'label')
    return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.${fieldState}`
  },
  fetcher: (ids: string[], data) => fetchReceptionsByIds(ids, undefined, data),
  getTitle: getReceptionTitle,
  relationTranslationFiles: ['consignmentItems', 'purchaseOrders', 'checklists'],
  getDimension: (entity, entityField) => null,
  getUnit: (entity, entityField) => null,
  parser: parseReception,
  linkType: {
    type: 'navigation',
    url: '/receptions',
  },
  parseDetails: (details) => details.flatMap(
    (detail) => detail.field != 'config' ?
      detail :
      Object.keys(detail.new_value)
        .filter((key) => detail.new_value[key] != detail.old_value[key])
        .map((key) => ({
          ...detail,
          field: key,
          new_value: detail.new_value[key],
          old_value: detail.old_value[key],
        }),
        ),
  ),

}

