import { toArray } from 'utils/mapperHelper'

type TranslationKeyWithOptions = {
  transkey: string
  options: Record<string, any>
}

export type ResolveTranslationOptionsType = {
  translation?: string
  translationKey?: string | (string | TranslationKeyWithOptions)[]
  exists: (key: string) => boolean
}

export function resolveTranslation(t: Function, options: ResolveTranslationOptionsType) {
  const translationKeys = toArray(options.translationKey)
    .map<TranslationKeyWithOptions>((translation) => {
      if (typeof translation === 'string') return { transkey: translation, options: {} }

      return translation
    })

  if (!translationKeys.length) return options.translation

  return translationKeys
    .map((key) => t(key.transkey, key.options)).join('\n')
}

export function getExistingTranslation(t: Function, options: ResolveTranslationOptionsType) {
  if (
    !options.translationKey || (Array.isArray(options.translationKey) && !options.translationKey.length)
  ) return options.exists(options.translation) ? options.translation : null

  if (!Array.isArray(options.translationKey)) options.translationKey = [options.translationKey]

  return options.translationKey.find((key: string) => options.exists(key))
}
