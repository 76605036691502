import { mathHelper, WeightInfo } from '@alix/math-helper'
import { DefaultRecord } from 'types/utils'

import { Digit } from 'utils/numberParser'
import { convertFromBase, convertToBase } from 'utils/unitConverter'

import { Inventory } from 'reducers/inventories/inventoriesSlice'

type MapData = {
  defaultUnits?: {
    qty: string
    weight: string
    length: string
    surface: string
    volume: string
  },
  culture?: string
  measureDigits?: Digit
}

export type ScaleObject = Pick<
  Inventory,
  '_measureUnit' |
  '_tareUnit' |
  '_weightUnit' |
  'currentMeasure' |
  'dimension' |
  'grossWeight' |
  'id' |
  'measureUnit' |
  'netWeight' |
  'onOrderMeasure' |
  'tareTypeId' |
  'tareWeight' |
  'unitWeight'
>

type WeightInfoOptions = {
  baseWeight?: number
  isGrossOrNet?: boolean
  tare?: any
}

export function getWeightInfo(
  scaleObject: Pick<ScaleObject, 'dimension' | 'currentMeasure' | 'tareWeight' | 'unitWeight'>,
  field: keyof ScaleObject,
  options: WeightInfoOptions,
): WeightInfo {
  let weightInfo: WeightInfo

  try {
    const data = {
      dimension_to_display: scaleObject.dimension,
      tare_weight: scaleObject.tareWeight,
      current_measure: scaleObject.currentMeasure,
      unit_weight: scaleObject.unitWeight,
      weight_input_is_gross_or_net: undefined,
    }

    if (field === 'tareWeight') {
      data.weight_input_is_gross_or_net = options?.isGrossOrNet
      weightInfo = mathHelper.getWeightInfoFromTare(data, options?.baseWeight)
    } else if (field === 'netWeight') {
      weightInfo = mathHelper.getWeightInfoFromNet(data, options?.baseWeight)
    } else if (field === 'grossWeight') {
      weightInfo = mathHelper.getWeightInfoFromGross(data, options?.baseWeight)
    } else if (field === 'unitWeight') {
      weightInfo = mathHelper.getWeightInfoFromUnitWeight(data, options?.baseWeight)
    } else if (field === 'tareTypeId') {
      data.weight_input_is_gross_or_net = options?.isGrossOrNet
      weightInfo = mathHelper.getWeightInfoFromTareObject(options?.tare, data)
    }
  } catch (err) {
    console.error(err)
  }

  return weightInfo
}

export function getWeightUnit(scaleObject: ScaleObject, options: Pick<MapData, 'defaultUnits'>) {
  const dimension = scaleObject.dimension
  const measureUnit = scaleObject._measureUnit
  const weightUnit = scaleObject._weightUnit

  return (dimension === 'weight' ? measureUnit : weightUnit) || options?.defaultUnits?.weight
}

export function getTareUnit(scaleObject: ScaleObject, options: Pick<MapData, 'defaultUnits'>) {
  return scaleObject._tareUnit || options?.defaultUnits?.weight
}

export function convertGrossWeight(scaleObject: ScaleObject, options: Pick<MapData, 'defaultUnits'>) {
  return +convertFromBase(
    'weight',
    +scaleObject.grossWeight,
    getWeightUnit(scaleObject, options),
    true,
  )
}

export function convertNetWeight(scaleObject: ScaleObject, options: Pick<MapData, 'defaultUnits'>) {
  return +convertFromBase(
    'weight',
    +scaleObject.netWeight,
    getWeightUnit(scaleObject, options),
    true,
  )
}

export function convertTareWeight(scaleObject: ScaleObject, options: Pick<MapData, 'defaultUnits'>) {
  return +convertFromBase(
    'weight',
    +scaleObject.tareWeight,
    getTareUnit(scaleObject, options),
    true,
  )
}

export function getMeasureUnit(
  scaleObject: Pick<ScaleObject, 'dimension' | '_measureUnit'>,
  options: Pick<MapData, 'defaultUnits'>,
): string {
  const dimension = scaleObject.dimension
  const unit = scaleObject._measureUnit

  return unit || options?.defaultUnits?.[dimension]
}

function getSingleToBase(scaleObject: ScaleObject, options: Pick<MapData, 'defaultUnits'>) {
  return convertToBase(scaleObject.dimension?.toString(), 1, getMeasureUnit(scaleObject, options))
}

export function convertUnitWeight(scaleObject: ScaleObject, options: Pick<MapData, 'defaultUnits'>) {
  const dimension = scaleObject.dimension
  return dimension === 'weight' ?
    0 :
    +convertFromBase(
      'weight',
      +scaleObject.unitWeight,
      getWeightUnit(scaleObject, options),
      true,
    ) * getSingleToBase(scaleObject, options)
}

export const convertObjectToScaleObject = <T extends DefaultRecord>(
  object: T,
  mapper: Record<keyof ScaleObject, keyof T>,
) => {
  if (!object) return {} as ScaleObject

  return Object.keys(mapper).reduce((acc, key) => {
    acc[key] = object[mapper[key]]
    return acc
  }, {} as ScaleObject)
}

export const convertScaleObjectToObject = <T extends DefaultRecord>(
  scaleObject: ScaleObject,
  mapper: Record<keyof ScaleObject, keyof T>,
) => {
  return Object.keys(mapper).reduce((acc, key) => {
    acc[mapper[key] as keyof T] = scaleObject[key]
    return acc
  }, {} as T)
}

export function getConvertedOnOrderMeasure(
  scaleObject: Pick<ScaleObject, 'dimension' | 'onOrderMeasure' | '_measureUnit'>,
  options: Pick<MapData, 'defaultUnits'>,
) {
  return +convertFromBase(
    scaleObject.dimension,
    +scaleObject.onOrderMeasure,
    getMeasureUnit(scaleObject, options),
    true,
  )
}
