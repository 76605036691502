import { ApiToSlice, BaseEntityApi, Modify, GetFields } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { EntityType } from 'utils/entities'
import { Digit } from 'utils/numberParser'
import { parsedResultOnSucessOrEmtpy, safeFetchJson } from 'utils/safeFetch'
import { convertFromBase } from 'utils/unitConverter'

const dataSetName = 'readingRegister'

const initialState = {
  fields: getReadingRegisterFields(),
}

type ReadingRegisterApi = Modify<
  {
    name: string;
    type: string;
    dimension: string;
    step_id: number;
    device_id: string;
    equipment_id: string;
    measure: number;
    measure_unit: string;
    entity_name: string;
    entity_id: string;
    reading_date_time: Date;
    create_date: Date;
    modified_date: Date;

    steps_title: string;
    equipment_title: string;
    device_title: string;
  },
  BaseEntityApi
>;

export type ReadingRegister = ApiToSlice<ReadingRegisterApi>;

export type ReadingRegisterMapData = {
  defaultUnits?: {
    qty: string
    weight: string
    length: string
    surface: string
    volume: string
  }
  measureDigits?: Digit
}

export type ReadingRegisterGetFields = GetFields<ReadingRegisterApi, ReadingRegister, ReadingRegisterMapData>;

function getMeasureUnit(data: ReadingRegisterApi, options: ReadingRegisterMapData): string {
  const dimension = data.dimension
  const unit = data.measure_unit

  return unit || options?.defaultUnits?.[dimension]
}

function parseInitialMeasure(data: ReadingRegisterApi, options: ReadingRegisterMapData) {
  return +convertFromBase(
    data.dimension?.toString(),
    +data.measure,
    getMeasureUnit(data, options),
    true,
  )
}

export function getReadingRegisterFields(): ReadingRegisterGetFields {
  return {
    id: { dataSetName, dbField: 'id', type: 'id' },
    name: {
      dataSetName,
      dbField: 'name',
      type: 'string',
      isDefaultSort: true,
      defaultSortOrder: -1,
    },
    type: {
      dataSetName,
      dbField: 'type',
      type: 'enum',
      values: ['scale', 'manual'],
      translationPath: 'readingsRegister:readingRegister.fields.type.values',
    },
    dimension: { dataSetName, dbField: 'dimension', type: 'string' },
    stepId: { dataSetName, dbField: 'step_id', type: 'integer' },
    // TODO (odeschenes): add relation when `devices` is implemented
    deviceId: { dataSetName, dbField: 'device_id', type: 'id' },
    equipmentId: {
      dataSetName,
      dbField: 'equipment_id',
      type: 'id',
      relationEntity: 'equipments',
    },
    measure: {
      dataSetName,
      dbField: 'measure',
      type: 'measure',
      parse: parseInitialMeasure,
    },
    createdDate: { dataSetName, dbField: 'created_date', type: 'timestamp' },
    modifiedDate: { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    readingDateTime: { dataSetName, dbField: 'reading_date_time', type: 'timestamp' },
    measureUnit: { dataSetName, dbField: 'measure_unit', type: 'string' },
    entityName: { dataSetName, dbField: 'entity_name', type: 'entity' },
    entityId: {
      dataSetName,
      dbField: 'entity_id',
      // Technically an ID, but we do not know the relation entity wihout fetching
      type: 'string',
      customEventTranslationKey: `readingsRegister:readingRegister.events.entityId`,
    },
    stepsTitle: {
      dataSetName: 'steps',
      dbField: 'title',
      dataSetAlias: 'steps',
      type: 'string',
    },
    equipmentTitle: {
      dataSetName: 'equipment',
      dbField: 'title',
      dataSetAlias: 'equipment',
      type: 'string',
    },
    deviceTitle: {
      parse: (data) => data.device_title,
    },
    createdBy: { dataSetName, dbField: 'created_by', type: 'string' },
    createdById: { dataSetName, dbField: 'created_by_id', type: 'id', relationEntity: 'resources' },
    modifiedBy: { dataSetName, dbField: 'modified_by', type: 'string' },
    modifiedById: { dataSetName, dbField: 'modified_by_id', type: 'id', relationEntity: 'resources' },
  }
}

export function getReadingRegisterTitle(entityData: ReadingRegister): string {
  return entityData.id
}

export async function fetchReadingRegisters(
  fetchData: Record<string, any> = {},
  mapData: ReadingRegisterMapData = {},
) {
  const response = await safeFetchJson(
    buildGetUrl(`/new_api/readings-register`, fetchData),
  )

  return parsedResultOnSucessOrEmtpy(response, parseReadingRegister, mapData)
}

export async function fetchReadingRegistersByIds(ids: string[], mapData?: Record<string, any>) {
  const response = await safeFetchJson(buildGetUrl(`/new_api/readings-register/${ids}`, mapData))

  return parsedResultOnSucessOrEmtpy(response, parseReadingRegister, mapData)
}

export async function fetchReadingRegistersCount(data: any) {
  const response = await safeFetchJson(
    buildGetUrl(`/new_api/readings-register/count`, data),
  )

  return response.isSuccess ? Number(response.result[0]?.count) : 0
}

export function parseReadingRegister(client: ReadingRegisterApi, options: ReadingRegisterMapData): ReadingRegister {
  const _options = {
    ...options,
    defaultData: getDefault(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  return parse(client, _options)
}

function getDefault(): ReadingRegister {
  return parse({}, { fields: initialState.fields })
}

export const ReadingRegisterEntity: EntityType<ReadingRegister> = {
  translationFile: 'readingsRegister',
  coreRelationName: 'readingRegister',
  getFields: getReadingRegisterFields,
  namespace: (entity, field) => {
    const idFields = ['step', 'device', 'equipment', 'entity']

    if (idFields.includes(field)) {
      field += 'Id'
    }

    return `${entity.translationFile}:${entity.coreRelationName}.fields.${field}.label`
  },
  fetcher: fetchReadingRegistersByIds,
  getTitle: (readingRegister) => readingRegister.name,
  relationTranslationFiles: ['items'],
  getDimension: (entity) => entity?.dimension,
  getUnit: (entity) => entity?.measureUnit,
  parser: parseReadingRegister,
}
