import { DefaultRecord } from 'types/utils'

import {
  baseComplexColumnHeader,
  getPrefixedColumnsHelpers,
  PrefixedComplexColumnsParams,
} from 'components/alix-front/legacy-smart-grid/columns/utils'
import {
  mapColumns,
  SmartGridColumnType,
} from 'components/alix-front/smart-grid/columns/utils'

import { ReceptionEntity } from 'reducers/receptions/receptionsSlice'
import { Reception } from 'reducers/receptions/receptionType'

const defaultOptions = {
  sortable: true,
  filter: true,
  isGlobalSearch: true,
}

type ColumnDictType = Partial<
  Record<keyof Reception, SmartGridColumnType<Reception>>
>;

export function getPrefixedComplexColumns<
  T extends DefaultRecord = DefaultRecord
>(params: PrefixedComplexColumnsParams = {}) {
  const { prefixColumnDict, prefixComplexColumns, prefixField } =
    getPrefixedColumnsHelpers<T>({
      ...params,
      formatHeader: baseComplexColumnHeader(
        'receptions:smartGridHeaderWithPrefix',
      ),
    })

  const columnDict: ColumnDictType = {
    name: {
      field: 'name',
      isDefaultSort: true,
      defaultSortOrder: -1,
      ...defaultOptions,
      isColumnToggle: false,
      addPrefixToHeader: false,
    },
    referenceNumber: { field: 'referenceNumber', ...defaultOptions },
    status: { field: 'status' },
    formattedReceiptDate: {
      field: 'formattedReceiptDate',
      addPrefixToHeader: false,
      ...defaultOptions,
    },
    plannedReceiptPostingDate: {
      field: 'plannedReceiptPostingDate',
      addPrefixToHeader: false,
      ...defaultOptions,
    },
    carrier: { field: 'carrier', ...defaultOptions },
    vendorDisplayName: { field: 'vendorDisplayName', ...defaultOptions },
  }

  const prefixedColumnDict = prefixColumnDict(columnDict)

  function getComplexColumns(
    t,
    { dateColumnTemplate, hideHours, culture, fields },
  ) {
    const complexColumns = mapColumns(Object.values(prefixedColumnDict), {
      t,
      fields,
      entity: ReceptionEntity,
      handlerOptions: {
        culture,
        useEntityTranslation: true,
      },
    }).reduce((acc, column) => {
      acc[column.field] = column

      return acc
    }, {} as Record<keyof Reception, SmartGridColumnType<Reception>>)

    return prefixComplexColumns(
      {
        ...complexColumns,
        [prefixField('formattedReceiptDate')]: {
          ...complexColumns[prefixField('formattedReceiptDate')],
          template: (props) =>
            dateColumnTemplate(
              props[prefixField('formattedReceiptDate')],
              hideHours,
            ),
        },
      },
      prefixedColumnDict,
      {
        t,
        keysAlreadyPrefixed: true,
      },
    )
  }

  return {
    getComplexColumns,
    columnDict: prefixedColumnDict,
    prefixField,
  }
}

const { columnDict, getComplexColumns } = getPrefixedComplexColumns()
export { columnDict, getComplexColumns }
