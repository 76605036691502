import moment from 'moment'

import { buildGetUrl, parse } from 'utils/api'
import { safeFetch, safeFetchJson } from 'utils/safeFetch'

import {
  GET_PROJECTS,
  GET_PROJECTS_COUNT,
  DELETE_PROJECTS,
} from './types'

const dataSetName = 'projectView'

const fields = getFields()
const initialState = {
  dataSetName,
  fields,
  projectsCount: 0,
  projects: [],
}

const _unallowedDuplicateTypes = ['manufacturing']

export default function projectsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_PROJECTS_COUNT: {
    return {
      ...state,
      projectsCount: payload,
    }
  }
  case GET_PROJECTS: {
    return {
      ...state,
      projects: payload,
    }
  }
  default: {
    return state
  }
  }
}

export function getFields() {
  return {
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'plantId': { dataSetName, dbField: 'plant_id', type: 'id', relationEntity: 'plants' },
    'title': { dataSetName, dbField: 'title' },
    'status': {
      dataSetName,
      dbField: 'status',
      customEventValueTranslationKey: (value) => `projects:status.${value ?? 'draft'}`,
    },
    'type': { dataSetName, dbField: 'type' },
    'formattedNumber': { dataSetName, dbField: 'formated_number' },
    'plantName': { dataSetName, dbField: 'plant_name' },
    'clientId': { dataSetName, dbField: 'client_id' },
    'clientDisplayName': { dataSetName, dbField: 'client_display_name' },
    'clientCompanyName': { dataSetName, dbField: 'client_company_name' },
    'ownerLastName': { dataSetName, dbField: 'owner_lastname' },
    'ownerFirstName': { dataSetName, dbField: 'owner_firstname' },
    'clientOnLabel': { dataSetName, dbField: 'client_on_label' },
    'promisedDate': {
      dataSetName,
      dbField: 'promised_date',
      type: 'date',
      parse: (project) => !!Date.parse(project.promised_date) ?
        moment(project.promised_date).utc().format('YYYY-MM-DD') :
        null,
      isTimezoned: false,
    },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },
    'newItemCount': { dataSetName, dbField: 'new_item_count', type: 'number' },
    'totalItemCount': { dataSetName, dbField: 'total_item_count', type: 'number' },
  }
}

export async function _fetchProjectsCount(data = {}) {
  data = parseFetchProjectsData(data)
  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/projects/count', data))).json()
    if (result.isSuccess) {
      return +result.result[0]?.count || 0
    }
  } catch (err) {
    console.error(err)
  }
  return 0
}

export function fetchProjectsCount(data) {
  return async function fetchProjectsCountThunk(dispatch) {
    const count = await _fetchProjectsCount(data)
    dispatch({ type: GET_PROJECTS_COUNT, payload: count })
    return count
  }
}

export function duplicateProjects(projectIds, state = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      'projectIds': projectIds,
      'shownInSales': state.shownInSales,
      'hiddenFromSales': state.hiddenFromSales,
      'isCancel': state.isCancel,
    }),
  }

  return safeFetchJson(`/new_api/projects/copy`, requestOptions)
}

export async function _fetchProjects(data) {
  let projects = []

  data = parseFetchProjectsData(data)
  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/projects', {
      ...data,
      excludeItems: true,
    }))).json()
    if (result.isSuccess) {
      projects = result.result.map((project) => parseProject(project))
    }
  } catch (err) {
    console.error(err)
  }

  return projects
}

export function fetchProjects(data) {
  return async function fetchProjectsThunk(dispatch) {
    const projects = await _fetchProjects(data)
    dispatch({ type: GET_PROJECTS, payload: projects })
    return projects
  }
}

export async function fetchProjectByIds(ids, data) {
  if (!ids?.length) return []

  data = parseFetchProjectsData(data)
  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/projects/${ids}`, { ...data, excludeItems: true }),
  )

  return isSuccess ? result.map((project) => parseProject(project)) : []
}

export async function fetchProject(projectId) {
  let project

  try {
    const result = await (await safeFetch(buildGetUrl(`/new_api/projects/${projectId}`))).json()
    if (result.isSuccess) {
      [project] = result.result.map((project) => parseProject(project))
    }
  } catch (err) {
    console.error(err)
  }

  return project
}

export async function fetchProjectIndex(id, data = {}) {
  let index = 0

  if (id) {
    try {
      data = parseFetchProjectsData(data)
      const result = await (await safeFetch(buildGetUrl(`/new_api/projects/${id}/index`, data))).json()
      if (result.isSuccess) {
        index = +result.result || 0
      }
    } catch (err) {
      console.error(err)
    }
  }

  return index
}

function parseCondition(conditionObj) {
  return Array.isArray(conditionObj) ? JSON.stringify(conditionObj) : conditionObj
}

function parseFetchProjectsData(data = {}) {
  const parsedData = { ...data }
  if (parsedData.conditionObj) {
    parsedData.conditionObj = parseCondition(parsedData.conditionObj)
  }

  return parsedData
}

export function getDisplayTitleFromApi(project) {
  return `${project.title || ''} ${project.formated_number}`
}

export function parseProject(project) {
  const options = {
    defaultData: getDefaultProject(),
    fields: initialState.fields,
    dataSetName,
  }
  const displayTitle = getDisplayTitleFromApi(project)
  const ownerFullName = `${project.owner_firstname || ''} ${project.owner_lastname || ''}`

  return {
    ...parse(project, options),
    displayTitle,
    ownerFullName,
  }
}

function getDefaultProject() {
  return parse({}, { fields })
}

export function getProjectTitle(project) {
  return project.displayTitle
}

export function deleteProjects(ids) {
  return async function deleteProjectsThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/projects/${ids}`, { method: 'DELETE' })).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_PROJECTS, payload: !!result.isSuccess, error })
      return result
    } catch (error) {
      dispatch({ type: DELETE_PROJECTS, payload: false, error })
      console.error(error)
    }
  }
}

export function canDuplicateProjects(projects) {
  return projects.every((project) => !_unallowedDuplicateTypes.includes(project.type))
}

export function canDeleteProjects(projects) {
  return !projects.some((project) => +project.totalItemCount > +project.newItemCount)
}

/**
 * @returns {import('reducers/smart-form/smartFormTypes').SmartFormOptionObject}
 */
export const getProjectDropdownOptions = ({ fetchData = {} } = { fetchData: {} }) => {
  return {
    key: 'projects',
    fetchOnFirstOpen: true,
    fetcher: _fetchProjects,
    indexFetcher: fetchProjectIndex,
    countFetcher: _fetchProjectsCount,
    fields: initialState.fields,

    fetchData: {
      ...fetchData,
      exist: true,
    },
    filterFieldKeys: ['title', 'formattedNumber'],
    orderByFieldKeys: [
      {
        key: 'title',
        isNullFirst: true,
      },
      {
        key: 'id',
        isNullFirst: true,
      },
    ],
    isLazy: true,
  }
}
