import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'

import { EntityName, getEntityObject } from 'utils/entities'

import { saveComment } from 'reducers/resources/resourcesSlice'

const initialState = {
  ids: null, htmlValue: '', textValue: '', isActiveChange: false,
}

const sessonStorageCode = 'richTextEditor'

type richTextEditorState = typeof initialState
type editorType = 'Instructions' | 'Comments'

// data is used to pass callbackData to front
export type richTextEditorActionFunctionState = Omit<richTextEditorState, 'isActiveChange' | 'ids'> & { data?: any}

type actionOptions = {
  type: editorType,
  actionFunction?: richTextEditorActionFunction,
  entityName?: EntityName,
  idColumn?: string,
  resourceId?: string,
}

export type richTextEditorActionFunction = (
  ids: string[],
  state: richTextEditorActionFunctionState,
  mapData?: any,
  options?: actionOptions
) => Promise<richTextEditorActionFunctionState>

const messageCallbacksSlice = createSlice({
  name: 'messageCallbacks',
  initialState,
  reducers: {
    setRichTextEditor: (state, action: {type: string, payload: {data: richTextEditorState}}) => {
      /**
       * ! We need to do the following because we have the react app that opens an item FC
       * which in turn opens a new react app. They then both have different reducers.
       * Session storage allows to centralize the data in one place.
       * This was implemented for instruction blur on FC close.
       */
      state = action.payload.data
      sessionStorage.setItem(sessonStorageCode, JSON.stringify(state))
    },
  },
})

export const { setRichTextEditor } = messageCallbacksSlice.actions

export const isEmptyHtml = (htmlValue: string) => {
  if (!htmlValue) return true

  return htmlValue === '<p><br></p>'
}

export const notifyFrontOfChange = (ids: string[], type: editorType, data: any) =>
  window.parent.postMessage({ call: `update${type}FromReact`, ids, data })

export function setRichTextEditorState(newState: richTextEditorState): any {
  return function setRichTextEditorStateThunk(dispatch: AppDispatch) {
    dispatch(setRichTextEditor({ data: newState }))
  }
}

export function updateRichTextEditorState(newState: Partial<richTextEditorState>): any {
  return function updateRichTextEditorStateThunk(dispatch: AppDispatch) {
    const currenState = _getRichTextEditorState()
    dispatch(setRichTextEditorState({ ...currenState, ...newState }))
  }
}

export function handleOnChangeAction(
  ids: string[],
  options: actionOptions,
  setInitialValue?: (htmlValue: string) => void,
  mapData?: any,
): any {
  return async function handleChangeActionThunk(dispatch: AppDispatch) {
    if (!options?.actionFunction) return

    const currenState = _getRichTextEditorState()
    const newState = await options?.actionFunction?.(ids, currenState, mapData, options)

    dispatch(setRichTextEditorState({ ...currenState, ...newState }))
    setInitialValue?.(newState.htmlValue)
    notifyFrontOfChange(ids, options.type, newState.data)
  }
}

export function handleOnBlurAction(
  ids: string[],
  options: actionOptions,
  setInitialValue?: (htmlValue: string) => void,
  forcedState: richTextEditorActionFunctionState = null,
  mapData?: any,
) {
  return async function handleOnBlurActionThunk(dispatch: AppDispatch) {
    if (forcedState) {
      dispatch(setRichTextEditorState({ ...forcedState, isActiveChange: true, ids }))
    }

    const currenState = _getRichTextEditorState()

    if (!currenState?.isActiveChange) return

    dispatch(setRichTextEditorState({ ...currenState, isActiveChange: false }))
    dispatch(handleOnChangeAction(ids, options, setInitialValue, mapData))
  }
}

function _getRichTextEditorState() {
  let currentState: richTextEditorState
  try {
    currentState = JSON.parse(sessionStorage.getItem(sessonStorageCode))
  } catch (err) {
    sessionStorage.removeItem(sessonStorageCode)
    console.error(err)
  }
  return currentState
}

export async function onInstructionBlur(
  ids: string[],
  state: richTextEditorActionFunctionState,
  mapData: any = {},
  options: actionOptions,
): Promise<richTextEditorActionFunctionState> {
  const entity = getEntityObject(options?.entityName)
  const additionnalInfo = entity.richTextEditorFields
  const items = await entity.updater?.(
    ids,
    [{ [additionnalInfo?.htmlValue]: state.htmlValue, [additionnalInfo?.textValue]: state.textValue }])

  // when we update multiple items, we only want to refresh with value of first item
  const item = items?.find((item) => item.id === ids[0])
  return {
    textValue: item?.[additionnalInfo?.textValue],
    htmlValue: item?.[additionnalInfo?.htmlValue],
    data: items,
  }
}

function buildCommentBody(ids: string[], resourceId: string, idColumn: string, htmlValue: string, textValue: string) {
  const data = { type: 'batch', values: [] }
  const defaultData = [
    { column: 'comments', value: htmlValue },
    { column: 'comments_bi', value: textValue },
  ]

  if (ids.length > 1) {
    ids.forEach((id) => {
      const subData = [...defaultData]
      subData.push({ column: idColumn, value: id })
      data.values.push(subData)
    })
  } else {
    defaultData.push({ column: idColumn, value: ids[0] })
    data.values.push(defaultData)
  }

  return {
    data,
    resourceId: resourceId,
  }
}

export async function onCommentBlur(
  ids: string[],
  state: richTextEditorActionFunctionState,
  mapData: any = {},
  options: actionOptions,
): Promise<richTextEditorActionFunctionState> {
  let comments = []
  const body = buildCommentBody(ids, options?.resourceId, options?.idColumn, state.htmlValue, state.textValue)
  const response = await saveComment(JSON.stringify(body))

  if (response.isSuccess) {
    comments = response.result
  }

  return {
    textValue: '',
    htmlValue: '',
    data: comments,
  }
}

export default messageCallbacksSlice.reducer
