import React from 'react'

import EmailIndicator from 'components/alix-front/email-indicator/EmailIndicator'
import {
  getPrefixedColumnsHelpers,
  baseComplexColumnHeader,
} from 'components/alix-front/legacy-smart-grid/columns/utils'
import SmartStatus from 'components/alix-front/smart-status/SmartStatus'

export const getFulfillmentStatusColumn = (t, prefixField, measureField, statusField) => {
  const simple = {
    field: 'fulfillmentStatus',
    sortable: true,
    filter: true,
    filterElement: { type: 'multiselect' },
  }

  if (typeof t != 'function') {
    return simple
  }

  return {
    ...simple,
    header: t('salesOrders:salesOrder.fields.fulfillmentStatus'),
    filterElement: {
      ...simple.filterElement,
      options: [
        {
          label: t('salesOrders:fulfillmentStatus.backorder'),
          value: 'backorder',
        },
        {
          label: t('salesOrders:fulfillmentStatus.unfulfilled'),
          value: 'unfulfilled',
        },
        {
          label: t('salesOrders:fulfillmentStatus.partially_fulfilled'),
          value: 'partially_fulfilled',
        },
        {
          label: t('salesOrders:fulfillmentStatus.fulfilled'),
          value: 'fulfilled',
        },
      ],
      itemTemplate: (option) => statusColumnFilterTemplate(option, 'fulfillmentStatus'),
    },
    template: (props) => {
      if (+props[prefixField(measureField)] < 0) return

      return statusColumnTemplate(props, 'fulfillmentStatus', props[prefixField(statusField)])
    },
  }
}

export const statusColumnFilterTemplate = (option, type) => {
  return (
    <SmartStatus
      className="a-smart-grid-icon-info"
      status={option.value}
      dictionaryKey="salesOrder"
      type={type}
    />
  )
}

export const statusColumnTemplate = (props, type, value) => {
  return (
    <SmartStatus
      className="a-smart-grid-icon-info"
      status={value}
      dictionaryKey="salesOrder"
      type={type}
    />
  )
}

/**
 * @param {import('components/alix-front/legacy-smart-grid/columns/utils').PrefixedComplexColumnsParams} params
 */
export function getPrefixedComplexColumns(
  params = {}) {
  const { prefixColumnDict, prefixComplexColumns, prefixField } = getPrefixedColumnsHelpers({
    ...params,
    formatHeader: baseComplexColumnHeader('salesOrders:smartGridHeaderWithPrefix'),
  })

  const getStatusColumn = (t, itemTemplate, template) => {
    const simple = {
      field: 'status',
      sortable: true,
      filter: true,
      filterElement: { type: 'multiselect' },
    }

    if (typeof t != 'function') {
      return simple
    }

    return {
      ...simple,
      header: t('salesOrders:salesOrder.fields.status'),
      filterElement: {
        ...simple.filterElement,
        options: [
          { label: t('salesOrders:status.draft'), value: 'draft' },
          {
            label: t('salesOrders:status.quote_submitted'),
            value: 'quote_submitted',
          },
          { label: t('salesOrders:status.on_hold'), value: 'on_hold' },
          { label: t('salesOrders:status.open'), value: 'open' },
          { label: t('salesOrders:status.closed'), value: 'closed' },
          { label: t('salesOrders:status.canceled'), value: 'canceled' },
        ],
        itemTemplate,
      },
      template,
    }
  }

  const getInvoiceStatusColumn = (t, itemTemplate, template) => {
    const simple = {
      field: 'invoiceStatus',
      sortable: true,
      filter: true,
      filterElement: { type: 'multiselect' },
    }

    if (typeof t != 'function') {
      return simple
    }

    return {
      ...simple,
      header: t('salesOrders:salesOrder.fields.invoiceStatus'),
      filterElement: {
        ...simple.filterElement,
        options: [
          {
            label: t('salesOrders:invoiceStatus.to_invoice'),
            value: 'to_invoice',
          },
          {
            label: t('salesOrders:invoiceStatus.partially_invoiced'),
            value: 'partially_invoiced',
          },
          { label: t('salesOrders:invoiceStatus.invoiced'), value: 'invoiced' },
        ],
        itemTemplate,
      },
      template,
    }
  }

  const getPaymentStatusColumn = (t, itemTemplate, template) => {
    const simple = {
      field: 'paymentStatus',
      sortable: true,
      filter: true,
      filterElement: { type: 'multiselect' },
    }

    if (typeof t != 'function') {
      return simple
    }

    return {
      ...simple,
      header: t('salesOrders:salesOrder.fields.paymentStatus'),
      filterElement: {
        ...simple.filterElement,
        options: [
          {
            label: t('salesOrders:paymentStatus.payment_pending'),
            value: 'payment_pending',
          },
          {
            label: t('salesOrders:paymentStatus.partially_paid'),
            value: 'partially_paid',
          },
          { label: t('salesOrders:paymentStatus.paid'), value: 'paid' },
        ],
        itemTemplate,
      },
      template,
    }
  }

  const columnDict = {
    name: {
      field: 'name',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
      isColumnToggle: false,
    },
    projectName: {
      field: 'projectName',
      sortable: true,
      hidden: true,
      filter: true,
    },
    salesOrderDate: { field: 'salesOrderDate', sortable: true, filter: true },
    referenceNumber: {
      field: 'referenceNumber',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    quoteNumber: {
      field: 'quoteNumber',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    plantName: {
      field: 'plantName',
      sortable: true,
      hidden: true,
      filter: true,
    },
    customer: {
      field: 'customer',
      sortable: true,
      filter: true,
      isGlobalSearch: true,
    },
    status: getStatusColumn(),
    desiredDate: {
      field: 'desiredDate',
      sortable: true,
      filter: true,
      hidden: true,
    },
    promisedDate: { field: 'promisedDate', sortable: true, filter: true },
    fulfillmentStatus: getFulfillmentStatusColumn(),
    invoiceStatus: getInvoiceStatusColumn(),
    paymentStatus: getPaymentStatusColumn(),
    shipmentNumberAggregate: {
      field: 'shipmentNumberAggregate',
      sortable: false,
      filter: true,
      hidden: true,
    },
    createdBy: {
      field: 'createdBy',
      sortable: true,
      filter: true,
      hidden: true,
      isGlobalSearch: true,
    },
    createdDate: {
      field: 'createdDate',
      sortable: true,
      filter: true,
      hidden: true,
    },
    modifiedBy: {
      field: 'modifiedBy',
      sortable: true,
      filter: true,
      hidden: true,
      isGlobalSearch: true,
    },
    modifiedDate: {
      field: 'modifiedDate',
      sortable: true,
      filter: true,
      hidden: true,
    },
    shipToAttention: {
      field: 'shipToAttention',
      sortable: true,
      filter: true,
      hidden: true,
    },
    shipToStreet: {
      field: 'shipToStreet',
      sortable: true,
      filter: true,
      hidden: true,
    },
    shipToStreet2: {
      field: 'shipToStreet2',
      sortable: true,
      filter: true,
      hidden: true,
    },
    shipToCity: {
      field: 'shipToCity',
      sortable: true,
      filter: true,
      hidden: true,
    },
    shipToState: {
      field: 'shipToState',
      sortable: true,
      filter: true,
      hidden: true,
    },
    shipToZip: {
      field: 'shipToZip',
      sortable: true,
      filter: true,
      hidden: true,
    },
    shipToCountry: {
      field: 'shipToCountry',
      sortable: true,
      filter: true,
      hidden: true,
    },
    shipToPhone: {
      field: 'shipToPhone',
      sortable: true,
      filter: true,
      hidden: true,
    },
    salespersonName: {
      field: 'salespersonName',
      sortable: true,
      filter: true,
      hidden: true,
    },
    salespersonEmail: {
      field: 'salespersonEmail',
      sortable: true,
      filter: true,
      hidden: true,
    },
    salespersonPhone: {
      field: 'salespersonPhone',
      sortable: true,
      filter: true,
      hidden: true,
    },
  }

  const prefixedColumnDict = prefixColumnDict(columnDict)

  function getComplexColumns(
    t,
    { language = 'fr', dateColumnTemplate },
  ) {
    const complexColumns = {
      name: {
        header: t('salesOrders:salesOrder.fields.salesOrder'),
        template: (props) => {
          if (!props[prefixField('id')]) {
            return null
          }
          return (
            <div>
              {props[prefixField('name')]}
              <EmailIndicator
                emailingDetails={props[prefixField('emailingDetails')]}
                language={language}
              />
            </div>
          )
        },
      },
      projectName: { header: t('salesOrders:salesOrder.fields.project') },
      salesOrderDate: {
        header: t('salesOrders:salesOrder.fields.salesOrderDate'),
        template: (props) => dateColumnTemplate(props[prefixField('salesOrderDate')], true),
      },
      referenceNumber: {
        header: t('salesOrders:salesOrder.fields.referenceNumber'),
      },
      quoteNumber: { header: t('salesOrders:salesOrder.fields.quoteNumber') },
      plantName: { header: t('salesOrders:salesOrder.fields.plant') },
      customer: { header: t('salesOrders:salesOrder.fields.customer') },
      status: getStatusColumn(
        t,
        (option) => statusColumnFilterTemplate(option, 'status'),
        (props) => statusColumnTemplate(props, 'status', props[prefixField('status')]),
      ),
      desiredDate: {
        header: t('salesOrders:salesOrder.fields.desiredDate'),
        template: (props) => dateColumnTemplate(props[prefixField('desiredDate')], true),
      },
      promisedDate: {
        header: t('salesOrders:salesOrder.fields.promisedDate'),
        template: (props) => dateColumnTemplate(props[prefixField('promisedDate')], true),
      },
      fulfillmentStatus: getFulfillmentStatusColumn(t, prefixField, 'measureSum', 'fulfillmentStatus'),
      invoiceStatus: getInvoiceStatusColumn(
        t,
        (option) => statusColumnFilterTemplate(option, 'invoiceStatus'),
        (props) =>
          +props[prefixField('measureSum')] > 0 ?
            statusColumnTemplate(props, 'invoiceStatus', props[prefixField('invoiceStatus')]) :
            undefined,
      ),
      paymentStatus: getPaymentStatusColumn(
        t,
        (option) => statusColumnFilterTemplate(option, 'paymentStatus'),
        (props) =>
          +props[prefixField('measureSum')] > 0 && +props[prefixField('invoicedSum')] > 0 ?
            statusColumnTemplate(props, 'paymentStatus', props[prefixField('paymentStatus')]) :
            undefined,
      ),
      shipmentNumberAggregate: {
        header: t('salesOrders:salesOrder.fields.shipmentNumberAggregate'),
      },
      createdBy: { header: t('salesOrders:salesOrder.fields.createdBy') },
      createdDate: {
        header: t('salesOrders:salesOrder.fields.createdDate'),
        template: (props) => dateColumnTemplate(props[prefixField('createdDate')]),
      },
      modifiedBy: { header: t('salesOrders:salesOrder.fields.modifiedBy') },
      modifiedDate: {
        header: t('salesOrders:salesOrder.fields.modifiedDate'),
        template: (props) => dateColumnTemplate(props[prefixField('modifiedDate')]),
      },
      shipToAttention: {
        header: t('addresses:address.fields.attention.label'),
      },
      shipToStreet: {
        header: t('addresses:address.fields.street.label'),
      },
      shipToStreet2: {
        header: t('addresses:address.fields.street2.label'),
      },
      shipToCity: {
        header: t('addresses:address.fields.city.label'),
      },
      shipToState: {
        header: t('addresses:address.fields.state.label'),
      },
      shipToZip: {
        header: t('addresses:address.fields.zip.label'),
      },
      shipToCountry: {
        header: t('addresses:address.fields.country.label'),
      },
      shipToPhone: {
        header: t('addresses:address.fields.phone.label'),
      },
      salespersonName: {
        header: t('salesOrders:salesOrder.fields.salesperson'),
        template: (props) => {
          return props[prefixField('salespersonName')]
        },
      },
      salespersonEmail: {
        header: t('salesOrders:salesOrder.fields.salespersonEmail'),
        template: (props) => {
          return props[prefixField('salespersonEmail')]
        },
      },
      salespersonPhone: {
        header: t('salesOrders:salesOrder.fields.salespersonPhone'),
        template: (props) => {
          return props[prefixField('salespersonPhone')]
        },
      },
    }

    return prefixComplexColumns(complexColumns, prefixedColumnDict, { t })
  }
  return {
    getComplexColumns,
    columnDict: prefixedColumnDict,
  }
}

const { columnDict, getComplexColumns } = getPrefixedComplexColumns()

export { columnDict, getComplexColumns }
