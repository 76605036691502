export type Digit = {
  minimumFractionDigits: number
  maximumFractionDigits: number
} | number

function _getFractionDigits(digits: Digit = 2) {
  let minimumFractionDigits
  let maximumFractionDigits

  if (typeof digits === 'object') {
    minimumFractionDigits = !isNaN(digits.minimumFractionDigits) ? digits.minimumFractionDigits : 2
    maximumFractionDigits = !isNaN(digits.maximumFractionDigits) ? digits.maximumFractionDigits : minimumFractionDigits
  } else if (!isNaN(digits)) {
    minimumFractionDigits = digits < 2 ? digits : 2
    maximumFractionDigits = digits
  }
  return { minimumFractionDigits, maximumFractionDigits }
}

export function parseNumber(number: string | number = 0, digits?: Digit, culture = 'fr-CA') {
  digits = digits ?? 2
  if (isNaN(+number)) {
    return number
  }

  const formatter = new Intl.NumberFormat(culture, _getFractionDigits(digits))

  return formatter.format(+number)
}

export function parsePercentage(percentage = 0, digits: Digit = 2, culture = 'fr-CA') {
  const formatter = new Intl.NumberFormat(culture, {
    style: 'percent',
    ..._getFractionDigits(digits),
  })

  const formatParts = formatter.formatToParts(percentage)
  return formatParts.map(({ value }) => value).reduce((string, part) => string + part)
}

export function parseCurrency(
  price = 0,
  currencyCode = 'CAD',
  currencySymbol = '$',
  digits: Digit = 2,
  culture = 'fr-CA',
) {
  const formatter = new Intl.NumberFormat(culture, {
    style: 'currency',
    currency: currencyCode,
    ..._getFractionDigits(digits),
  })

  const formatParts = formatter.formatToParts(price)

  const [firstFormattedElement] = formatParts
  const isCurrencyFirst = firstFormattedElement.type === 'currency' || firstFormattedElement.type === 'minusSign'

  return formatParts.map(({ type, value }) => {
    switch (type) {
    case 'currency':
      if (currencySymbol) {
        return `${currencySymbol.trim()}${isCurrencyFirst ? ' ' : ''}`
      }
      return `${value.trim()}${isCurrencyFirst ? ' ' : ''}`
    default:
      return value
    }
  }).reduce((string, part) => string + part)
}

export function reverseCurrencyParse(string, currencyCode = 'CAD', currencySymbol = '$', culture = 'fr-CA') {
  const formatter = new Intl.NumberFormat(culture, {
    style: 'currency',
    currency: currencyCode,
  })

  const formatParts = formatter.formatToParts(1111.1)
  const group = formatParts.find((obj) => obj.type === 'group')
  const decimal = formatParts.find((obj) => obj.type === 'decimal')

  let reversedString = string.replace(new RegExp(`\\${currencySymbol}`, 'g'), '')
  if (group) {
    reversedString = reversedString.replace(new RegExp(`\\${group.value}`, 'g'), '')
  }
  if (decimal) {
    reversedString = reversedString.replace(new RegExp(`\\${decimal.value}`, 'g'), '.')
  }

  const price = +(reversedString.trim())
  return Number.isNaN(price) ? 0 : price
}

export function isCurrencySymbolFirst(price = 0, currencyCode = 'CAD', culture = 'fr-CA') {
  const formatter = new Intl.NumberFormat(culture, {
    style: 'currency',
    currency: currencyCode,
  })

  const [firstFormattedElement] = formatter.formatToParts(price)
  return firstFormattedElement.type === 'currency'
}

export function getCurrencySymbol(price = 0, currencyCode = 'CAD', culture = 'fr-CA') {
  const formatter = new Intl.NumberFormat(culture, {
    style: 'currency',
    currency: currencyCode,
  })

  const symbol = formatter.formatToParts(price).find((obj) => obj.type === 'currency')
  return symbol ? symbol.value : ''
}

/**
 * Applies operator on a list of numbers
 */
export function calculate(operator: ('+' | '-' | '*' | '/'), numbers: number[], decimals: number = 0): number {
  let result = numbers[0]
  let resultDecimals = decimals

  numbers.forEach((number, index) => {
    const numberDecimals = countDecimals(number)
    if (operator === '+' || operator === '-') {
      resultDecimals = Math.max(resultDecimals, numberDecimals)
    } else if (operator === '*') {
      resultDecimals += numberDecimals
    } else if (operator === '/') {
      resultDecimals = decimals
    } else {
      return
    }

    if (index > 0) {
      result = _calculate[operator](result, number)
    }
  })

  return roundNumber(result, resultDecimals)
}

const _calculate = {
  '+': (firstNumber, secondNumber) => {
    return +firstNumber + +secondNumber
  },
  '-': (firstNumber, secondNumber) => {
    return +firstNumber - +secondNumber
  },
  '*': (firstNumber, secondNumber) => {
    return +firstNumber * +secondNumber
  },
  '/': (firstNumber, secondNumber) => {
    return +firstNumber / +secondNumber
  },
}

/**
   * Gets a float's number decimal precision
   */
export function countDecimals(number: number): number {
  if (Math.floor(number) === number) {
    return 0
  }
  return number.toString().split('.')[1]?.length || 0
}

export function roundNumber(num: string | number, scale: number) {
  if (!(`${num}`).includes('e')) {
    return +(`${Math.round(Number(`${num}e+${scale}`)) }e-${scale}`)
  } else {
    const arr = (`${num}`).split('e')
    let sig = ''
    if (+arr[1] + scale > 0) {
      sig = '+'
    }
    return +(`${Math.round(Number(`${+arr[0]}e${sig}${+arr[1] + scale}`)) }e-${scale}`)
  }
}

/**
 * Return the minimum number for a given number of decimals.
 * @param decimals The number of decimals to consider.
 * @returns The minimum number possible.
 */
export function minNumber(decimals: number): number {
  return Math.pow(10, -decimals)
}

/**
 * Splits a given total into a specified number of parts.
 * This function divides the total amount into equal parts but adjusts
 * for floating-point precision errors to ensure that the sum of the parts
 * is exactly equal to the original total.
 *
 * @param total The total amount to split.
 * @param parts The number of parts to split the total into.
 * @returns {Object[]} An array of objects, each containing:
 *  - `base`: The initial unadjusted split value.
 *  - `adjusted`: The split value adjusted to ensure the total sum is exactly the original total.
 */

export function splitNumber(total, parts, decimals = 0) {
  const base = calculate('/', [total, parts], decimals)
  const totalBase = calculate('*', [base, parts])
  const delta = calculate('-', [total, totalBase])
  const adjusted = calculate('+', [base, delta])

  return {
    base: base,
    adjusted: adjusted,
  }
}
