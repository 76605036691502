import { mapColumns } from 'components/alix-front/smart-grid/columns/utils'

import { entities } from 'utils/entities'

const defaultOptions = {
  sortable: true,
  filter: true,
  isGlobalSearch: true,
}

export const columnDict = {
  description: { field: 'description', isColumnToggle: false, ...defaultOptions },
  secondaryDescription: { field: 'secondaryDescription', ...defaultOptions },
  status: { field: 'status', filterElement: { type: 'multiselect' }, ...defaultOptions },
  type: { field: 'type', ...defaultOptions },
  createdBy: { field: 'createdBy', ...defaultOptions },
  createdDate: { field: 'createdDate', ...defaultOptions },
  modifiedBy: { field: 'modifiedBy', ...defaultOptions },
  modifiedDate: { field: 'modifiedDate', ...defaultOptions },
}

export function getComplexColumns(t, { fields, primaryLanguage, secondaryLanguage, simpleDict }) {
  const complexColumns = mapColumns(
    Object.values(simpleDict),
    { t, fields, entity: entities['attributes'], handlerOptions: {
      useEntityTranslation: true,
      primaryLanguage,
      secondaryLanguage,
    } },
  )

  return complexColumns.reduce((acc, column) => {
    acc[column.field] = column
    return acc
  }, {})
}
