import Joi from 'joi'

import { SmartFormFieldType } from 'reducers/smart-form/smartFormTypes'

export const getMaxLength = (schema: Joi.Schema) => {
  const max = schema.$_getRule('max')

  if (!max) return

  return max.args.limit
}

export const getGreaterThan = (schema: Joi.Schema) => {
  const greaterThan = schema.$_getRule('greater')

  if (!greaterThan) return

  return greaterThan.args.limit
}

export const getIsRequired = (schema: Joi.Schema) => {
  return schema?._flags?.presence === 'required'
}

export const getInputTypeFromJoiSchema = <F extends SmartFormFieldType>(schema: Joi.Schema) => {
  return schema?.type as F['type']
}

export const parseSchema = <F extends SmartFormFieldType>(schema: Record<string, Joi.Schema>, field: F): F => {
  const _schema = schema[field.name]

  if (!_schema) return field

  return {
    maxLength: getMaxLength(_schema) ?? undefined,
    isRequired: getIsRequired(_schema) ?? false,
    greaterThan: getGreaterThan(_schema) ?? undefined,
    type: getInputTypeFromJoiSchema<F>(_schema),
    ...field,
  }
}

/**
 * Schema for a url
 *
 * Ex:
 *  ✅ - https://www.google.com
 *  ✅ - google.com
 *  ❌ - google
 *
 * @param {(Joi.StringSchema) => Joi.StringSchema} subSchema - Sub schema to apply to the url schema
 * @returns {Joi.Schema}
 */
export const urlSchema = (subSchema = (schema) => schema) => {
  const base = Joi
    .string()
    .regex(/^(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/)
  return subSchema(base)
}
