import React, { useState, useEffect, useRef, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'

import equal from 'fast-deep-equal/react'
import { v4 as uuid } from 'uuid'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown } from 'primereact/dropdown'

import SmartGrid from 'components/alix-front/legacy-smart-grid/SmartGrid'
import SmartButton from 'components/alix-front/smart-button/SmartButton'
import SmartPopup from 'components/alix-front/smart-popup/SmartPopup'

import { debounceByKey } from 'utils/debounce'
import { rows, debounceInitialFetch, fetch, debounceLazyLoad } from 'utils/virtualScrollerHelper'

import './style.css'

const debounceFilter = debounceByKey(
  'smart-dropdown-filter',
  ({ t, setLazyItems, filter, stateFilter, lazyData, optionLabel, filterFieldKeys, value }) => {
    if (!stateFilter) return

    const fetchData = lazyData.fetchData || {}
    const mapData = lazyData.mapData || {}
    fetch(lazyData, fetchData, mapData, value, filterFieldKeys)
      .then(({ fetched, lazyItems, count }) => {
        if (!!filter && !fetched.length) {
          lazyItems[count + 1] = { _filter: filter }
          lazyItems[count + 1][optionLabel] = t('common:noRecords')
        }
        setLazyItems((_lazyItems) => {
          const newLazyItems = { items: lazyItems, index: _lazyItems.index }
          if (equal(newLazyItems, _lazyItems)) return _lazyItems
          return newLazyItems
        })
      })
  },
  500,
)

const getInitialPopupState = () => {
  return {
    selection: null,
    isOpen: false,
  }
}

/**
 * @typedef {Object} Props
 * @property {string} [className]
 * @property {string} [wrapperClassName]
 * @property {any} [options]
 * @property {any} [onChange]
 * @property {string | number} [value]
 * @property {string} [placeholder]
 * @property {string} [filterPlaceholder]
 * @property {string} [optionValue]
 * @property {string} [optionLabel]
 * @property {any} [optionGroupChildren]
 * @property {any} [optionGroupLabel]
 * @property {any} [optionGroupTemplate]
 * @property {boolean} [showClear]
 * @property {any} [filter]
 * @property {any} [itemTemplate]
 * @property {any} [valueTemplate]
 * @property {any} [lazyData]
 * @property {boolean} [disabled]
 * @property {boolean} [required=false]
 * @property {string} [panelClassName]
 * @property {string} [id]
 * @property {boolean} [defaultToFirstIfSingle]
 * @property {any} [optionDisabled]
 * @property {boolean} [editable]
 * @property {number} [maxLength]
 * @property {(ids: string[], fetchData?: Record<string, any>) => Promise<any[]>} [fetcherByIds]
 * @property {{
 *  dataHandler: Record<string, any>
 *  popupTitleKey: string
 *  dataKey: string
 *  isActive: boolean
 * }} [advancedFilter]
 */

const _lazyData = {}
const _advancedFilter = {}

/**
 * @param {Props} props
 * @return {React.ReactElement}
 */
function SmartDropdown({
  className,
  wrapperClassName,
  options,
  onChange,
  value: initialValue = null,
  placeholder,
  filterPlaceholder,
  optionValue,
  optionLabel,
  optionGroupChildren,
  optionGroupLabel,
  optionGroupTemplate,
  optionDisabled,
  showClear = false,
  filter = true,
  itemTemplate,
  valueTemplate,
  lazyData = _lazyData,
  disabled = false,
  required = false,
  panelClassName,
  id,
  defaultToFirstIfSingle = false,
  advancedFilter = _advancedFilter,
  fetcherByIds,
  editable = false,
  maxLength,
}, ref) {
  const { t } = useTranslation(['common'])
  const dropdownRef = useRef(null)
  const [valueItem, setValueItem] = useState({ isFetched: false, data: null })
  const [lazyItems, setLazyItems] = useState({ items: [], index: null })
  const [isFetching, setIsFetching] = useState(true)
  const [isInitialFetch, setIsInitialFetch] = useState(false)
  const [stateFilter, setStateFilter] = useState(false)
  const [firstOpen, setFirstOpen] = useState({ active: true, index: 0, fetching: false })
  const [conditionObj, setConditionObj] = useState(JSON.stringify(lazyData.fetchData?.conditionObj || []))
  const [popupState, setPopupState] = useState(getInitialPopupState())
  const maxLengthRef = useRef(null)

  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const { fields: filterFields, keys: filterFieldKeys } = useMemo(() => ({
    fields: Object.values(lazyData.filterFields || {}),
    keys: Object.keys(lazyData.filterFields || {}),
  }), [lazyData.filterFields])

  const _fetchDebounceUuid = useMemo(() => uuid(), [])
  const _debounceInitialFetch = useMemo(
    () => debounceInitialFetch(_fetchDebounceUuid, setIsFetching),
    [_fetchDebounceUuid],
  )
  const _debounceLazyLoad = useMemo(() => debounceLazyLoad(_fetchDebounceUuid, setIsFetching), [_fetchDebounceUuid])
  useImperativeHandle(ref, () => ({
    initialFetch: (options = {}) => _debounceInitialFetch({
      lazyData,
      setLazyItems,
      options: { value, filterFieldKeys, ...options },
    }),
  }))

  useEffect(() => {
    if (valueItem.isFetched || !value || optionValue != 'id' || typeof fetcherByIds != 'function' || editable) return
    fetcherByIds([value], { includeDeleted: true })
      .then(([valueItem]) => setValueItem({ isFetched: true, data: valueItem }))
  }, [fetcherByIds, optionValue, value, valueItem.isFetched, editable])

  useEffect(() => {
    if (valueItem.data && value !== valueItem.data[optionValue]) {
      setValueItem({ isFetched: false, data: null })
    }
  }, [value, optionValue, valueItem.data])

  useEffect(() => {
    if (!!lazyData.lazy && !lazyData.fetchOnFirstOpen && !isInitialFetch) {
      _debounceInitialFetch({
        lazyData,
        setLazyItems,
        options: { value, filterFieldKeys },
      }).then((lazyItems) => {
        if (defaultToFirstIfSingle && lazyItems.length == 1) {
          onChange({ value: lazyItems[0][optionValue] }, lazyItems)
        }
        setIsInitialFetch(true)
      })
    }
  }, [
    _debounceInitialFetch,
    lazyData,
    filterFieldKeys,
    value,
    optionLabel,
    optionValue,
    isInitialFetch,
    defaultToFirstIfSingle,
    onChange,
  ])

  useEffect(() => {
    if (!firstOpen.active && firstOpen.index >= 0 && lazyItems.items.length > 0) {
      dropdownRef.current.getVirtualScroller()?.scrollToIndex(firstOpen.index)
    }
  }, [firstOpen, lazyItems.items.length])

  useEffect(() => {
    setStateFilter((!lazyData.lazy && filter) || (filterFields.length > 0 && filter))
  }, [filter, lazyData.lazy, filterFields.length])

  const onLazyLoad = useCallback((ev) => {
    _debounceLazyLoad({ setLazyItems, ev, lazyData: {
      ...lazyData,
      fetchData: { ...lazyData.fetchData, conditionObj },
    }, filterFieldKeys })
  }, [_debounceLazyLoad, lazyData, conditionObj, filterFieldKeys])

  const onFilter = useCallback((ev) => {
    if (!lazyData.lazy) return

    // filter double quotes and backslashes and replace them with escaped versions
    const _filterText = ev.filter.replace(/\\*\\/g, '\\\\').replace(/\\*"/g, '\\"')
    let _conditionObj = []
    if (lazyData.fetchData?.conditionObj?.length) {
      _conditionObj.push(...lazyData.fetchData.conditionObj)
    }
    if (_filterText) {
      _conditionObj.push(
        { type: 'orConditions', value: filterFields.map((fieldInfo) => ({
          dataSetName: fieldInfo.dataSetName,
          alias: fieldInfo.dataSetAlias,
          column: fieldInfo.dbField,
          value: encodeURIComponent(_filterText),
          comparator: 'ILIKE',
        })) },
      )
    }
    _conditionObj = JSON.stringify(_conditionObj)

    setConditionObj(_conditionObj)
    debounceFilter({ t, setLazyItems, filter: _filterText, stateFilter, lazyData: {
      ...lazyData,
      fetchData: { ...lazyData.fetchData, conditionObj: _conditionObj },
    }, optionLabel, filterFieldKeys, value })
  }, [t, lazyData, filterFields, filterFieldKeys, optionLabel, value, stateFilter, setConditionObj])

  const _loadingItemTemplate = useCallback((props) => (
    <div className="a-flex a-justify-center a-align-center">
      <FontAwesomeIcon
        className="a-spin"
        icon={['fad', 'spinner-third']}
      />
    </div>
  ), [])

  const isFirstOpenTrigger = useMemo(
    () => firstOpen.active && lazyData.lazy && lazyData.fetchOnFirstOpen,
    [firstOpen.active, lazyData.fetchOnFirstOpen, lazyData.lazy],
  )

  const _itemTemplate = useMemo(() => {
    if (!isFirstOpenTrigger && !firstOpen.fetching) return itemTemplate

    return _loadingItemTemplate
  }, [_loadingItemTemplate, isFirstOpenTrigger, firstOpen.fetching, itemTemplate])

  const items = useMemo(() => {
    let _items = []
    if (isFirstOpenTrigger || firstOpen.fetching) {
      const _data = lazyData.initialData || []
      if (!_data.length) {
        _data.push({ [optionValue]: '_first_open_loading' })
      }
      _items = _data
    } else if (!lazyData.lazy) {
      _items = options
    } else if (isFetching) {
      _items = lazyData.initialData || []
    } else {
      if (lazyData.creationRow) lazyItems.items.unshift(lazyData.creationRow)
      _items = lazyItems.items
    }

    if (
      (!lazyItems.index || lazyItems.index === -1) &&
      !!valueItem.data &&
      !_items.some((item) => item[optionValue] === valueItem.data[optionValue])
    ) _items = [valueItem.data, ..._items]

    _items
      .filter((item) => !!item[optionValue] && !item[optionValue].startsWith?.('_temp') && !item[optionLabel])
      .forEach((item) => item[optionLabel] = t('common:notAvailable'))

    return _items.map((item) => {
      if (typeof item === 'object') return { ...item }
      return item
    })
  }, [
    t,
    isFirstOpenTrigger,
    firstOpen.fetching,
    lazyData.lazy,
    lazyData.initialData,
    lazyData.creationRow,
    options,
    optionValue,
    optionLabel,
    isFetching,
    lazyItems.items,
    lazyItems.index,
    valueItem.data,
  ])

  const virtualScrollerOptions = useMemo(() => {
    if (!lazyData.lazy || items.length <= rows) {
      return
    }

    return {
      lazy: true,
      onLazyLoad,
      itemSize: 32,
      numToleratedItems: 8,
    }
  }, [onLazyLoad, lazyData.lazy, items.length])

  const handleChange = useCallback((ev, items) => {
    if (ev.value === '_first_open_loading') return

    const item = items.find((item) => item[optionValue] === ev.value)

    if (editable) {
      if (item) {
        if (editable) {
          ev.value = item[optionLabel]
        }
      }

      /**
       * (Technicly, item should always be defined if the `editable=false`, but we will check it anyway.)
       *
       * If the item is not defined, it means that the dropdown is in `editable=true` and the user is simply typing
       * in the input
       *
       * If the item is defined, but we are in `editable=true`, we want to send the label
       * of the item, else we send the id
       * and we update the value item.
       */
      if (maxLength && ev.value?.length > maxLength) {
      /**
       * If the item is not null,  the user has clicked on an item of the dropdown.
       * So we need to check if the value is longer than the max length. (should not happen, but to be safe)
       */
        if (item) {
          ev.value = ev.value?.substring(0, maxLength)
        } else {
        /**
         * If the item is null, the user has typed in the input.
         * So we need to check if the value is longer than the max length.
         * if so, we can simply skip the event
         */
          return
        }
      }

      setValue(ev.value)

      if (item || !ev.value) {
        onChange(ev, items, item)
      }
    } else {
      setValueItem((state) => ({ ...state, data: item }))
      onChange(ev, items, item)
    }
  }, [editable, onChange, optionLabel, optionValue, maxLength])

  const close = useCallback(() => setPopupState(getInitialPopupState()), [])

  const advancedSearchPopup = useMemo(() => {
    if (!advancedFilter.isActive) return null

    const selection = popupState.selection || (value ? { [advancedFilter.dataKey]: value } : null)
    const onConfirm = () => {
      if (!selection) return

      handleChange({ value: selection[advancedFilter.dataKey] }, advancedFilter.dataHandler.data)
      close()
    }
    return (
      <>
        <SmartButton
          className='a-advanced-search-button'
          isIconButton
          icon="magnifying-glass"
          onClick={() => {
            setPopupState({ isOpen: true })
          }}
        />
        <SmartPopup
          isOpen={popupState.isOpen}
          title={t(advancedFilter.popupTitleKey)}
          onCancel={close}
          onConfirm={onConfirm}
          isConfirm={!!selection}
          className='a-smart-popup-grid a-advanced-search-popup'
        >
          <SmartGrid
            dataHandler={advancedFilter.dataHandler}
            dataKey={advancedFilter.dataKey}
            selectionMode='single'
            selection={selection}
            onSelectionChange={(selection) => setPopupState({ ...popupState, selection })}
            lazy
            resizableColumns
            isSearchUrl={false}
            onKeyUp={(ev) => {
              if (ev.code === 'Enter') onConfirm()
            }}
          />
        </SmartPopup>
      </>
    )
  }, [
    advancedFilter.dataHandler,
    advancedFilter.dataKey,
    advancedFilter.isActive,
    advancedFilter.popupTitleKey,
    close,
    handleChange,
    popupState,
    t,
    value,
  ])

  const _className = useMemo(() => {
    const classNames = ['a-smart-dropdown']
    if (className) classNames.push(className)
    if (advancedFilter.isActive) classNames.push('a-advanced-search-dropdown')

    return classNames.join(' ')
  }, [advancedFilter.isActive, className])

  const _wrapperClassName = useMemo(() => {
    const wrapperClassNames = ['a-smart-dropdown-wrapper']
    if (wrapperClassName) wrapperClassNames.push(wrapperClassName)

    return wrapperClassNames.join(' ')
  }, [wrapperClassName])

  const maxLengthString = useMemo(
    () => `(${(value || '').length}/${maxLength})`,
    [value, maxLength],
  )

  const onInputBlur = useCallback((ev) => {
    if (!isNaN(maxLength)) {
      maxLengthRef.current?.classList.add('a-hidden')
    }

    if (!editable) return

    onChange(ev, items)
  }, [maxLength, editable, onChange, items])

  const onInputFocus = useCallback((ev) => {
    if (!isNaN(maxLength)) {
      maxLengthRef.current?.classList.remove('a-hidden')
    }
  }, [maxLength])

  return (
    <div className={_wrapperClassName}>
      {maxLength ? (
        <div
          ref={maxLengthRef}
          className="a-form-element-max-length a-hidden a-form-element-top-right-section"
        >
          {maxLengthString}
        </div>
      ) : null}
      <Dropdown
        inputId={id}
        onBlur={onInputBlur}
        onFocus={onInputFocus}
        ref={dropdownRef}
        className={_className}
        options={items}
        onChange={(ev) => handleChange(ev, items)}
        value={value}
        placeholder={placeholder}
        optionDisabled={optionDisabled}
        optionValue={optionValue}
        optionLabel={optionLabel}
        optionGroupChildren={optionGroupChildren}
        optionGroupLabel={optionGroupLabel}
        optionGroupTemplate={optionGroupTemplate}
        filter={stateFilter}
        filterBy={lazyData.lazy ? '_filter' : undefined}
        filterPlaceholder={filterPlaceholder}
        onFilter={onFilter}
        showClear={showClear}
        itemTemplate={_itemTemplate}
        valueTemplate={valueTemplate}
        virtualScrollerOptions={virtualScrollerOptions}
        required={required}
        disabled={disabled}
        panelClassName={panelClassName}
        editable={editable}
        onShow={() => {
          if (isFirstOpenTrigger) {
            setFirstOpen((firstOpen) => ({ ...firstOpen, active: false, fetching: true }))
            _debounceInitialFetch({
              lazyData,
              setLazyItems,
              options: { value, filterFieldKeys },
            }).then((lazyItems) => {
              const index = lazyItems.findIndex((item) => item[optionValue] === value)
              if (index === -1 && !!value) handleChange({ value: null }, items)

              setFirstOpen((firstOpen) => ({ ...firstOpen, fetching: false, index }))
            })
          }
        }}
      />
      {advancedSearchPopup}
    </div>
  )
}

export default forwardRef(SmartDropdown)
