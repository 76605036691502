import { toCamel } from 'utils/stringUtils'

export function buildGetUrl(url = '', data = {}) {
  const queryStrings = []
  Object.keys(data).forEach((key) => {
    queryStrings.push(`${key}=${data[key]}`)
  })

  if (queryStrings.length) {
    url += `?${queryStrings.join('&')}`
  }

  return url
}

/**
 * @param {*} data
 * @param {*} options
 * @param {*} options.apiPrefix The prefix to be removed for all the keys in the data object.
 * Used when the fields of an entity are used as a joined entity to another entity.
 * Note that this is only used during parsing, as the real field name is with the prefix.
 * @returns
 */
export function parse(data, options = {}) {
  let parsed = options.defaultData ? { ...options.defaultData } : {}

  if (!data) {
    return parsed
  }

  if (options.apiPrefix) {
    data = Object.keys(data).reduce((acc, key) => {
      if (!key.startsWith(options.apiPrefix)) return acc

      const prefixedKey = key.replace(options.apiPrefix, '')

      acc[prefixedKey] = data[key]

      return acc
    }, {})
  }

  const nonNullableTypes = ['number', 'boolean']
  const dataSetName = options.dataSetName || options.fields.id?.dataSetName
  const availableFields = Object.keys(data)
  const fieldsWithFormattedParse = []

  Object
    .keys(options.fields)
    .forEach((field) => {
      const fieldInfo = options.fields[field]

      /**
       * ! This logic does not work if the `fieldInfo.dbField` needs aliasing.
       * ! Could be moved to the else if condition `else if (fieldInfo.dbField)`
       * ! to access the real field name (`_dbField`).
       */
      if (options.onlyParseAvailableFields && !availableFields.includes(fieldInfo.dbField)) {
        return
      }

      let value = null
      if (typeof fieldInfo.parseWithParsedData === 'function') {
        fieldsWithFormattedParse.push(field)
        return
      } else if (typeof fieldInfo.parse === 'function') {
        value = fieldInfo.parse(data, options)
      } else if (fieldInfo.dbField) {
        let _dbField = fieldInfo.dbField

        const alias = fieldInfo.trimAlias || fieldInfo.dataSetAlias

        if ((fieldInfo.dataSetName && fieldInfo.dataSetName != dataSetName) || alias) {
          _dbField = `${alias || (fieldInfo.dataSetName || '').toLowerCase()}_${fieldInfo.dbField}`
        }

        value = nonNullableTypes.includes(typeof data[_dbField]) ? data[_dbField] : (
          data[_dbField] || (
            fieldInfo.hasOwnProperty('defaultValue') ? fieldInfo.defaultValue : null
          )
        )

        if (fieldInfo.lookupInOptions) {
          const optionsData = options[fieldInfo.lookupInOptions] || {}
          value = optionsData[fieldInfo.dbField] || value
        }
      }

      const keyHierarchy = field.split('.')
      buildHierarchyRecursive(parsed, keyHierarchy, value)
    })

  fieldsWithFormattedParse.forEach((field) => {
    const fieldInfo = options.fields[field]
    const value = fieldInfo.parseWithParsedData(parsed, options, data)

    const keyHierarchy = field.split('.')

    buildHierarchyRecursive(parsed, keyHierarchy, value)
  })

  if (options.outPrefix) {
    const shouldCamelCaseKeys = !options.skipOutPrefixCamelCaseKeys

    parsed = Object.keys(parsed).reduce((acc, key) => {
      const prefixedKey = shouldCamelCaseKeys ?
        toCamel(`${options.outPrefix}_${key}`) :
        `${options.outPrefix}${key}`

      acc[prefixedKey] = parsed[key]
      return acc
    }, {})
  }

  return parsed
}

function buildHierarchyRecursive(parsed, keyHierarchy, value, level = 0) {
  const currentKey = keyHierarchy[level]
  if (keyHierarchy.length === level + 1) {
    parsed[currentKey] = value
    return
  }

  parsed[currentKey] = parsed[currentKey] || {}
  return buildHierarchyRecursive(parsed[currentKey], keyHierarchy, value, level + 1)
}

export function resolveDottedPath(obj, path) {
  return path.split('.').reduce((prev, curr) => prev ? prev[curr] : undefined, obj)
}
