import { createSlice } from '@reduxjs/toolkit'

import { buildGetUrl } from 'utils/api'
import { EntityType } from 'utils/entities'
import { SafeFetchResponse, isJob, parsedResultOnSucessOrEmtpy, safeFetchJson } from 'utils/safeFetch'

import { FullReceptionItem, FullReceptionItemApi } from 'reducers/receptions/receptionType'
import { getLineItemFields, lineItemInitialState, parseReceptionItem } from 'reducers/receptions/shared'

type ItemsCountResult = {count: number}

const receptionsItemsSlice = createSlice({
  name: 'receptionLineItems',
  initialState: lineItemInitialState,
  reducers: {
    setReceptionItems: (state, action) => {
      state.data = action.payload.data
    },
    setReceptionItemsCount: (state, action) => {
      state.count = action.payload.count
    },
  },
})

export async function fetchReceptionItemsByIds(
  ids: FullReceptionItem['id'][],
  mapData: Record<string, any> = {},
  method: 'GET' | 'POST' = 'GET',
) {
  if (!ids?.length) return []

  let result: SafeFetchResponse<FullReceptionItemApi>
  if (method === 'GET') {
    result = await safeFetchJson<FullReceptionItemApi>(`/new_api/receptions/items/${ids}`)
  } else if (method === 'POST') {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ids }),
    }
    result = await safeFetchJson<FullReceptionItemApi>(`/new_api/receptions/items/fetch`, requestOptions)
  }

  return result.isSuccess && Array.isArray(result.result) ?
    result.result.map((receptionItem) => parseReceptionItem(receptionItem, mapData)) :
    []
}

export const { setReceptionItems, setReceptionItemsCount } = receptionsItemsSlice.actions

export function fetchReceptionItems(data: Record<string, any> = {}, mapData: Record<string, any> = {}) {
  return async function(dispatch) {
    const response = await safeFetchJson<FullReceptionItemApi>(
      buildGetUrl(`/new_api/receptions/items`, data),
    )

    const receptionItems = parsedResultOnSucessOrEmtpy(response, parseReceptionItem, mapData)

    dispatch(setReceptionItems({ data: receptionItems }))

    return receptionItems
  }
}

const getCount = (result: ItemsCountResult|ItemsCountResult[]) => {
  result = Array.isArray(result) ? result[0] : result

  return result?.count || 0
}

export function fetchReceptionItemsCount(data: Record<string, any> = {}) {
  return async function(dispatch) {
    const { isSuccess, result } = await safeFetchJson<ItemsCountResult>(
      buildGetUrl(`/new_api/receptions/items/count`, data),
    )

    const count = isSuccess && !isJob(result) ? getCount(result) : 0

    dispatch(setReceptionItemsCount({ count }))

    return count
  }
}

export default receptionsItemsSlice.reducer

export function getReceptionItemTitle(receptionItem: FullReceptionItem) {
  return `${receptionItem.receptionName} - ${receptionItem.templateTitle}`
}

export const ReceptionItemEntity: EntityType<FullReceptionItem> = {
  translationFile: 'receptions',
  coreRelationName: 'receptionItem',
  getFields: getLineItemFields,
  namespace: `receptions:reception.receptionItem.fields`,
  fetcher: (ids: string[]) => fetchReceptionItemsByIds(ids, undefined, 'POST'),
  getTitle: getReceptionItemTitle,
  relationTranslationFiles: ['locations', 'items', 'inventories', 'checklists'],
  getDimension: (entity) => {
    return entity?.dimension
  },
  getUnit: (entity) => {
    return entity?.measureUnit
  },
  parser: (data, options) => parseReceptionItem(data, options, options?.onlyParseAvailableFields),
}
