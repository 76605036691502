import { mapColumns } from 'components/alix-front/smart-grid/columns/utils'

import { ClientEntity, getClientFields } from 'reducers/clients/clientsSlice'
import { Client } from 'reducers/clients/types'

const ColumnsToShow: (keyof Client)[] = [
  'id', 'title', 'appversionName', 'type', 'alixDatabaseId',
]

const fields = getClientFields()

export const columnDict = Object
  .keys(fields)
  .reduce((acc, field: keyof Client) => {
    if (!ColumnsToShow.includes(field)) return acc

    const fieldData = fields[field]

    if (fieldData.translationPath) {
      delete fieldData.translationPath
    }

    acc[field] = {
      field: field,
      sortable: true,
      filter: true,
      ...fields[field],
    }
    return acc
  }, {} as Record<string, any>)

export function getComplexColumns(t, { fields, culture, simpleDict }) {
  const complexColumns = mapColumns(
    Object.values(simpleDict),
    { t, fields, entity: ClientEntity, handlerOptions: {
      culture,
      useEntityTranslation: true,
    } },
  )

  return complexColumns.reduce((acc, column) => {
    acc[column.field] = column
    return acc
  }, {})
}
