import React, { useMemo } from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'

import moment from 'moment'

function DateColumn({ date, hideHours = false, format = null }) {
  const loginUser = useSelector((state) => state.loginUser)
  const language = useMemo(() => loginUser.language || 'fr', [loginUser.language])
  const _format = useMemo(() => format || (language === 'en' ?
    `MMM DD YYYY${hideHours ? '' : ' [at] H:mm A'}` :
    `DD MMM YYYY${hideHours ? '' : ' [à] H[h]mm'}`
  ), [language, hideHours, format])
  return (date ?
    <Moment
      className="a-smart-grid-default-template"
      title={Date.parse(date) ?
        moment(date).format(_format) :
        ''}
      date={date}
      locale={`${language}-CA`}
      format={_format}
    /> : null
  )
}

export default DateColumn
