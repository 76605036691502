import { createSlice } from '@reduxjs/toolkit'
import { HubAppDispatch } from 'store'
import { FieldType } from 'types/slices'
import { DefaultRecord } from 'types/utils'

import { GLOBAL_SCOPE_ID } from 'components/alix-front/planning/filters/ScopeDropdown'

import { buildGetUrl, parse } from 'utils/api'
import { EntityType } from 'utils/entities'
import { isJob, safeFetchJson } from 'utils/safeFetch'
import { convertFromBase } from 'utils/unitConverter'

import {
  getFields as getTemplateFields,
  parseItem,
} from 'reducers/items/itemsSlice'
import {
  getLineItemFields as getSalesOrderItemFields,
  getFields as getSalesOrderFields,
  parseSalesOrder,
  parseSalesOrderLineItem,
} from 'reducers/sales-orders/shared'
import {
  FullShipmentPlanning,
  FullShipmentPlanningApi,
  ShipmentPlanningFields,
  shipmentPlanningSalesOrderApiPrefix,
  shipmentPlanningSalesOrderFieldPrefix,
  shipmentPlanningSalesOrderItemApiPrefix,
  shipmentPlanningSalesOrderItemFieldPrefix,
  shipmentPlanningTemplateApiPrefix,
  shipmentPlanningTemplateFieldPrefix,
} from 'reducers/shipment-planning/type'
import { formatFields } from 'reducers/utils/common'

const dataSetName = 'shipmentPlanningView'

const convertMeasureCb = (field) => (data, options: any = {}) => {
  const dimension = data.template_dimension_to_display
  const unit = data.template_measure_unit

  return convertFromBase(dimension, data[field], unit || options.defaultUnits, true) as number
}

const shipmentPlanningFields: ShipmentPlanningFields = {
  id: {
    /**
     * The fields are passed all at the same time, but their types are separated.
     * So the type does not know that the data incluses the sales order item data.
     */
    parse: (data) => (data as any).sales_order_item_id,
  },
  cardFormatedNumber: {
    dbField: 'card_formated_number',
    type: 'string',
    dataSetName,
  },
  cardCurrentStep: {
    dbField: 'card_current_step',
    type: 'string',
    dataSetName,
  },
  currentMeasureSummed: {
    dbField: 'current_measure_summed',
    useTrimAliasInField: true,
    type: 'measure',
    dataSetName,
    parse: convertMeasureCb('current_measure_summed'),
  },
  plannedMeasureSummed: {
    dbField: 'planned_measure_summed',
    useTrimAliasInField: true,
    type: 'measure',
    dataSetName,
    parse: convertMeasureCb('planned_measure_summed'),
  },
  // TODO (odeschenes) #DEV-I1522 : When we do this card, we should use the field in the sales order item getFields,
  // columnDict and getComplexColumns
  salesOrderItemMeasureRemainingToShip: {
    dbField: 'sales_order_item_measure_remaining_to_ship',
    useTrimAliasInField: true,
    type: 'measure',
    isTimezoned: false,
    dataSetName,
    parse: convertMeasureCb('sales_order_item_measure_remaining_to_ship'),
  },
  availableQuantity: {
    dbField: 'available_quantity',
    type: 'measure',
    parse: convertMeasureCb('available_quantity'),
  },
  availableMeasureSummed: {
    dbField: 'available_measure_summed',
    useTrimAliasInField: true,
    type: 'measure',
    dataSetName,
    parse: convertMeasureCb('available_measure_summed'),
  },
  salesOrderItemToShipStatus: {
    dataSetName,
    dbField: 'sales_order_item_to_ship_status',
    type: 'status',
    isEdit: false,
  },
  salesOrderToShipStatus: {
    dataSetName,
    dbField: 'sales_order_to_ship_status',
    type: 'status',
    isEdit: false,
  },
  readyToShipStatus: {
    parse: ({
      available_quantity: availableQuantity,
      sales_order_item_measure_remaining_to_ship: salesOrderItemMeasureRemainingToShip,
    }) => {
      if (availableQuantity >= salesOrderItemMeasureRemainingToShip) {
        return 'full'
      } else if (availableQuantity > 0) {
        return 'partial'
      } else {
        return 'noStock'
      }
    },
  },
}

const initialState = {
  dataSetName,
  fields: getShipmentPlanningFields(),
  salesOrderItemFields: getSalesOrderItemFields(),
  salesOrderFields: getSalesOrderFields(),
  templateFields: getTemplateFields(),
  count: 0,
  data: [],
}

export function getShipmentPlanningFields() {
  const dataSetName = 'shipmentPlanningView'

  const options: Partial<FieldType> = {
    dataSetName,
    useTrimAliasInField: true,
  }

  return {
    ...formatFields(
      getTemplateFields(),
      shipmentPlanningTemplateFieldPrefix,
      shipmentPlanningTemplateApiPrefix,
      options,
    ),
    ...formatFields(
      getSalesOrderFields(),
      shipmentPlanningSalesOrderFieldPrefix,
      shipmentPlanningSalesOrderApiPrefix,
      options,
    ),
    ...formatFields(
      getSalesOrderItemFields(),
      shipmentPlanningSalesOrderItemFieldPrefix,
      shipmentPlanningSalesOrderItemApiPrefix,
      options,
    ),
    ...shipmentPlanningFields,
  }
}

const slice = createSlice({
  name: 'shipment-planning',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload.data
    },
    setCount: (state, action) => {
      state.count = action.payload.count
    },
  },
})

export const { setData, setCount } = slice.actions

export function fetchShipmentPlanningCount(fetchData?: Record<string, any>) {
  return async function fetchClientsThunk(dispatch: HubAppDispatch) {
    const count = await _fetchShipmentPlanningCount(fetchData)

    dispatch(setCount({ count }))

    return count
  }
}

function getConditionObj(fetchData?: Record<string, any>) {
  const conditionObj = []

  if (fetchData.conditionObj) {
    conditionObj.push(
      ...JSON.parse(fetchData.conditionObj),
    )
  }

  if (fetchData.horizon) {
    conditionObj.push(
      {
        dataSetName: 'shipmentPlanningView',
        column: 'sales_order_item_promised_date',
        value: fetchData.horizon,
        comparator: '<=',
      },
    )
  }

  if (fetchData.plantId !== GLOBAL_SCOPE_ID) {
    conditionObj.push({
      dataSetName: 'shipmentPlanningView',
      column: 'sales_order_item_plant_id',
      value: fetchData.plantId,
    })
  }

  return JSON.stringify(conditionObj)
}

export function fetchShipmentPlanning(fetchData?: Record<string, any>, mapData = {}) {
  return async function fetchClientsThunk(dispatch: HubAppDispatch) {
    const data = await _fetchShipmentPlanning(fetchData, mapData)

    dispatch(setData({ data }))

    return data
  }
}

export async function _fetchShipmentPlanning(fetchData: Record<string, any> = {}, mapData = {}) {
  let data = []

  try {
    const { isSuccess, result } = await safeFetchJson<FullShipmentPlanningApi, true>(
      buildGetUrl('/new_api/shipments/planning', {
        ...fetchData,
        conditionObj: getConditionObj(fetchData),
      }),
    )
    if (isSuccess && !isJob(result)) {
      data = result.map((client) => parseShipmentPlanning(client, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return data
}

export async function _fetchShipmentPlanningCount(fetchData: Record<string, any> = {}) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}, true>(
      buildGetUrl('/new_api/shipments/planning/count', {
        ...fetchData,
        conditionObj: getConditionObj(fetchData),
      }),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

const getMapData = (mapData: DefaultRecord = {}) => {
  return {
    ...mapData,
    debug: true,

    fields: initialState.fields,
    dataSetName: dataSetName,
    defaultUnits: mapData?.defaultUnits,
    isPrimaryLanguage: mapData?.isPrimaryLanguage,
    isDocumentPrimaryLanguage: mapData?.isDocumentPrimaryLanguage,
    taxDict: mapData?.taxDict,
    priceMaxDigits: mapData?.priceMaxDigits,
    measureMaxDigits: mapData?.measureMaxDigits,

    vendorDetails: mapData.vendorDetails || {},
    customerDetails: mapData.customerDetails || {},
    preferredCompany: mapData.preferredCompany || {},
  } as const
}

export function parseShipmentPlanning(data: FullShipmentPlanningApi, mapData: DefaultRecord = {}) {
  const options = getMapData(mapData)

  const salesOrderItem = parseSalesOrderLineItem(
    { ...data } as any,
    {
      ...options,
      apiPrefix: `${shipmentPlanningSalesOrderItemApiPrefix}_`,
      outPrefix: `${shipmentPlanningSalesOrderItemFieldPrefix}`,
      skipOutPrefixCamelCaseKeys: true,
    })

  const salesOrder = parseSalesOrder(
    { ...data } as any, {
      ...options,
      apiPrefix: `${shipmentPlanningSalesOrderApiPrefix}_`,
      outPrefix: `${shipmentPlanningSalesOrderFieldPrefix}`,
      skipOutPrefixCamelCaseKeys: true,
    })

  const template = parseItem({ ...data }, {
    ...options,
    apiPrefix: `${shipmentPlanningTemplateApiPrefix}_`,
    outPrefix: `${shipmentPlanningTemplateFieldPrefix}`,
    skipOutPrefixCamelCaseKeys: true,
  })

  const shipmentPlanning = parse({ ...data }, {
    ...options,
    fields: shipmentPlanningFields,
  })

  const parsed = {
    ...salesOrderItem,
    ...salesOrder,
    ...template,
    ...shipmentPlanning,
  }

  return parsed
}

export const ShipmentPlanningEntity: EntityType<FullShipmentPlanning> = {
  translationFile: 'shipmentPlanning',
  coreRelationName: 'shipmentPlanning',
  getFields: getShipmentPlanningFields,
  relationTranslationFiles: [
    'salesOrders',
    'addresses',
    'items',
    'cards',
    'salesOrders',
    'addresses',
    'contacts',
    'projects',
    'shipments',
    'inventories',
    'consignmentItems',
    'shipmentPlanning',
  ],
  // Entity is not used in events, no need for these fields
  namespace: () => '',
  fetcher: async() => [],
  getTitle: () => '',
  getDimension: (data, entityField, field) => {
    switch (field.dataSetAlias) {
    case shipmentPlanningSalesOrderApiPrefix:
      return null
    case shipmentPlanningTemplateApiPrefix:
      return data.templateDimensionToDisplay
    case shipmentPlanningSalesOrderItemApiPrefix:
      return data.salesOrderItemDimensionToDisplay
    default:
      return null
    }
  },
  getUnit: (data, entityField, field) => {
    switch (field.dataSetAlias) {
    case shipmentPlanningSalesOrderApiPrefix:
      return null
    case shipmentPlanningTemplateApiPrefix:
    case shipmentPlanningSalesOrderItemApiPrefix:
      return data.templateDimensionToDisplay === 'weight' ?
        data.templateMeasureUnit : data.templateWeightUnit
    default:
      return null
    }
  },
  parser: parseShipmentPlanning,
}

export default slice.reducer
