import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { getPropertyWithFallback } from 'utils/defaultValueHelper'
import { isJob, safeFetch, safeFetchJson } from 'utils/safeFetch'

import {
  GET_ATTRIBUTES,
  GET_ATTRIBUTES_COUNT,
  CREATE_ATTRIBUTE,
  DELETE_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  GET_ATTRIBUTE_OPTIONS,
  GET_ATTRIBUTE_OPTIONS_COUNT,
} from './types'

const dataSetName = 'attributeView'
const optionsDataSetName = 'attributeOptionView'

const initialState = {
  dataSetName,
  fields: getFields(),
  attributesCount: 0,
  attributes: [],
  optionsDataSetName,
  optionsFields: getOptionsFields(),
  attributeOptionsCount: 0,
  attributeOptions: [],
}

export default function attributesReducer(state = initialState, action: any) {
  const { payload } = action
  switch (action.type) {
  case GET_ATTRIBUTES: {
    return {
      ...state,
      attributes: payload,
    }
  }
  case GET_ATTRIBUTES_COUNT: {
    return {
      ...state,
      attributesCount: payload,
    }
  }
  default: {
    return state
  }
  }
}

export type AttributeOptionsApi = Modify<{
  status: string
  description: string
  secondary_description: string
  display_description: string
  display_secondary_description: string
  color: string
  template_id: string
  template_sku: string
  template_description: string
  template_secondary_description: string
  material_title: string
  treatment_title: string
  template_part_number: string
  template_revision: string
  template_manufacturer: string
  category_title: string
  raw_imperial_title: string
  raw_metric_title: string
  attribute_id: string
  template_title: string
  use_sku_as_suffix: boolean
  sku_suffix: string
  display_sku_suffix: string
}, BaseEntityApi>

type AttributeOptionsExceptions = {
  material_title: 'templateMaterial'
  treatment_title: 'templateTreatment'
  category_title: 'templateCategory'
  raw_imperial_title: 'templateRawImperial'
  raw_metric_title: 'templateRawMetric'
}

export type AttributeOptions = ApiToSlice<
Modify<AttributeOptionsApi, {display_description_matching_language: string}>,
AttributeOptionsExceptions
>

export type AttributeApi = Modify<{
  status: string
  type: 'standard' | 'bom'
  description: string
  secondary_description: string
  attribute_options: AttributeOptionsApi[]
}, BaseEntityApi>

type AttributeExceptions = {attribute_options: 'lineItems'}

export type Attribute = ApiToSlice<Modify<AttributeApi, {
  attribute_options: AttributeOptions[],
  display_description_matching_language: string
}>, AttributeExceptions>

export type MapData = {
  primaryLanguage?: string
  secondaryLanguage?: string
  isPrimaryLanguage?: boolean
}

type AttributeGetFields = GetFields<AttributeApi, Attribute, MapData>

export function getFields(editOnly = false): AttributeGetFields {
  const editFields: AttributeGetFields = {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'status': {
      dataSetName,
      dbField: 'status',
      type: 'status',
      dictionaryKey: 'attribute',
      values: ['active', 'inactive'],
      customEventValueTranslationKey: (value) => `attributes:status.${value}`,
    },
    'type': {
      dataSetName,
      dbField: 'type',
      type: 'enum',
      translationPath: 'attributes:attribute.fields.type',
      values: ['standard', 'bom'],
      isEdit: true,
    },
    'description': {
      dataSetName,
      dbField: 'description',
      type: 'string',
      headerOptions: { language: 'primaryLanguage' },
      customFieldTranslationKey: (t, options) => {
        return t('attributes:attribute.fields.description.label', { language: options.primaryLanguage })
      },
      isEdit: true,
    },
    'secondaryDescription': {
      dataSetName,
      dbField: 'secondary_description',
      type: 'string',
      headerOptions: { language: 'secondaryLanguage' },
      customFieldTranslationKey: (t, options) => {
        return t('attributes:attribute.fields.description.label', { language: options.secondaryLanguage })
      },
      isEdit: true,
    },
    'displayDescriptionMatchingLanguage': {
      parse: (item: AttributeApi, options: MapData) => (
        getPropertyWithFallback(item, 'description', 'secondary_description', options?.isPrimaryLanguage)
      ),
    },
    'lineItems': { parse: (attribute, mapData) => {
      return (attribute?.attribute_options || []).map((attributeOption) => (
        parseAttributeOption(attributeOption, mapData)
      ))
    } },
  }

  if (editOnly) return editFields

  const fields: AttributeGetFields = {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'timestamp' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'string' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'string' },
  }

  return { ...fields, ...editFields }
}

type OptionGetFields = GetFields<AttributeOptionsApi, AttributeOptions, MapData>

export function getOptionsFields(editOnly = false): OptionGetFields {
  const editFields: OptionGetFields = {
    'id': { dataSetName: optionsDataSetName, dbField: 'id', type: 'string' },
    'status': {
      dataSetName: optionsDataSetName,
      dbField: 'status',
      type: 'status',
      dictionaryKey: 'attribute',
      values: ['active', 'inactive'],
      customEventValueTranslationKey: (value) => `attributes:status.${value}`,
    },
    'useSkuAsSuffix': {
      dataSetName: optionsDataSetName,
      dbField: 'use_sku_as_suffix',
      type: 'boolean',
      isEdit: true,
    },
    'skuSuffix': {
      dataSetName: optionsDataSetName,
      dbField: 'sku_suffix',
      type: 'string',
      isEdit: true,
    },
    'description': {
      dataSetName: optionsDataSetName,
      dbField: 'description',
      type: 'string',
      headerOptions: { language: 'primaryLanguage' },
      customFieldTranslationKey: (t, options) => {
        return t('attributes:attribute.fields.description.label', { language: options.primaryLanguage })
      },
      isEdit: true,
    },
    'secondaryDescription': {
      dataSetName: optionsDataSetName,
      dbField: 'secondary_description',
      type: 'string',
      headerOptions: { language: 'secondaryLanguage' },
      customFieldTranslationKey: (t, options) => {
        return t('attributes:attribute.fields.description.label', { language: options.secondaryLanguage })
      },
      isEdit: true,
    },
    'color': {
      dataSetName: optionsDataSetName,
      dbField: 'color',
      type: 'color',
      isEdit: true,
    },
    'templateId': {
      dataSetName: optionsDataSetName,
      dbField: 'template_id',
      type: 'id',
      isEdit: true,
      relationEntity: 'items',
    },
    'templateSku': {
      dataSetName: optionsDataSetName,
      dbField: 'template_sku',
      type: 'string',
      parse: (item) => item?.template_sku ?? '',
    },
    'templateDescription': {
      dataSetName: optionsDataSetName,
      dbField: 'template_description',
      type: 'string',
    },
    'templateSecondaryDescription': {
      dataSetName: optionsDataSetName,
      dbField: 'template_secondary_description',
      type: 'string',
    },
    'templateMaterial': {
      dataSetName: optionsDataSetName,
      dbField: 'material_title',
      type: 'string',
    },
    'templateTreatment': {
      dataSetName: optionsDataSetName,
      dbField: 'treatment_title',
      type: 'string',
    },
    'templateCategory': {
      dataSetName: optionsDataSetName,
      dbField: 'category_title',
      type: 'string',
    },
    'templateRawImperial': {
      dataSetName: optionsDataSetName,
      dbField: 'raw_imperial_title',
      type: 'string',
    },
    'templateRawMetric': {
      dataSetName: optionsDataSetName,
      dbField: 'raw_metric_title',
      type: 'string',
    },
    'templatePartNumber': {
      dataSetName: optionsDataSetName,
      dbField: 'template_part_number',
      type: 'string',
    },
    'templateRevision': {
      dataSetName: optionsDataSetName,
      dbField: 'template_revision',
      type: 'string',
    },
    'templateManufacturer': {
      dataSetName: optionsDataSetName,
      dbField: 'template_manufacturer',
      type: 'string',
    },
    'templateTitle': { parse: (item) => item?.template_title ?? '' },
  }

  if (editOnly) return editFields

  const fields: OptionGetFields = {
    'attributeId': { dataSetName: optionsDataSetName, dbField: 'attribute_id', type: 'string' },
    'exist': { dataSetName: optionsDataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName: optionsDataSetName, dbField: 'created_date', type: 'timestamp' },
    'createdBy': { dataSetName: optionsDataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName: optionsDataSetName, dbField: 'created_by_id', type: 'string' },
    'modifiedDate': { dataSetName: optionsDataSetName, dbField: 'modified_date', type: 'timestamp' },
    'modifiedBy': { dataSetName: optionsDataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName: optionsDataSetName, dbField: 'modified_by_id', type: 'string' },
    'displayDescription': {
      dataSetName: optionsDataSetName,
      dbField: 'display_description',
      type: 'string',
    },
    'displaySecondaryDescription': {
      dataSetName: optionsDataSetName,
      dbField: 'display_secondary_description',
      type: 'string',
    },
    'displayDescriptionMatchingLanguage': {
      parse: (item: AttributeOptionsApi, options: MapData) => (
        getPropertyWithFallback(
          item,
          'display_description',
          'display_secondary_description',
          options?.isPrimaryLanguage,
        )
      ),
    },
    'displaySkuSuffix': {
      dataSetName: optionsDataSetName,
      dbField: 'display_sku_suffix',
      type: 'string',
    },
  }

  return { ...fields, ...editFields }
}

// ------------- Attributes -------------

export function getAttributeTitle(attribute: Attribute): string {
  return attribute.description?.toString()
}

export async function fetchAttributesByIds(ids: (string|number)[], mapData?: MapData) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<AttributeApi>(buildGetUrl(`/new_api/attributes/${ids}`))

  return isSuccess && !isJob(result) ? result.map((attribute) => parseAttribute(attribute, mapData)) : []
}

export function fetchAttributes(data?: Record<string, any>, mapData?: MapData) {
  return async function fetchAttributesThunk(dispatch: AppDispatch) {
    const attributes = await _fetchAttributes(data, mapData)
    dispatch({ type: GET_ATTRIBUTES, payload: attributes })
    return attributes
  }
}

export async function _fetchAttributes(data: Record<string, any>, mapData?: MapData) {
  let attributes = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/attributes', data))).json()
    if (result.isSuccess) {
      attributes = result.result.map((attribute: AttributeApi) => parseAttribute(attribute, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return attributes
}

export function fetchAttributesCount(data?: Record<string, any>) {
  return async function fetchAttributesCountThunk(dispatch: AppDispatch) {
    const count = await _fetchAttributesCount(data)
    dispatch({ type: GET_ATTRIBUTES_COUNT, payload: count })
    return count
  }
}

export async function _fetchAttributesCount(data?: Record<string, any>) {
  let count = 0

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/attributes/count', data))).json()
    if (result.isSuccess) {
      count = +result.result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export async function _fetchAttributeIndex(id: string, data: any) {
  let index = 0

  if (id) {
    try {
      const result = await safeFetchJson(buildGetUrl(`/new_api/attributes/${id}/index`, data))
      if (result.isSuccess) {
        index = +result.result || 0
      }
    } catch (err) {
      console.error(err)
    }
  }

  return index
}

export function createAttributes(data: any[], mapData?: MapData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data }),
  }
  return async function createAttributesThunk(dispatch: AppDispatch) {
    try {
      const result = await (await safeFetch('/new_api/attributes', requestOptions)).json()
      const payload = result.isSuccess ? parseAttribute(result.result, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: CREATE_ATTRIBUTE, payload, error })
      return { isSuccess: result.isSuccess, attribute: payload }
    } catch (error) {
      console.error(error)
      dispatch({ type: CREATE_ATTRIBUTE, error })
      return { isSuccess: false }
    }
  }
}

export function deleteAttributes(ids: string[], isBatch = false) {
  if (!ids?.length) return []

  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: isBatch ? JSON.stringify({ ids }) : undefined,
  }
  const url = isBatch ? '/new_api/attributes/batch' : `/new_api/attributes/${ids}`
  return async function deleteAttributesThunk(dispatch: AppDispatch) {
    try {
      const result = await (await safeFetch(url, requestOptions)).json()
      const payload = result.isSuccess ? result.result : null
      dispatch({ type: DELETE_ATTRIBUTE, payload })
      return { isSuccess: result.isSuccess }
    } catch (error) {
      console.error(error)
      dispatch({ type: DELETE_ATTRIBUTE, error })
      return { isSuccess: false }
    }
  }
}

export async function _updateAttribute(id: string, data: any, mapData?: MapData) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data }),
  }

  try {
    const result = await (await safeFetch(`/new_api/attributes/${id}`, requestOptions)).json()
    const payload = result.isSuccess ? parseAttribute(result.result, mapData) : null
    return { isSuccess: result.isSuccess, attribute: payload }
  } catch (error) {
    console.error(error)
    return { isSuccess: false, error }
  }
}

export function updateAttribute(id: string, data: any, mapData?: MapData) {
  return async function updateAttributesThunk(dispatch: AppDispatch) {
    const { isSuccess, attribute, error } = await _updateAttribute(id, data, mapData)
    dispatch({ type: UPDATE_ATTRIBUTE, payload: attribute, error })
    return { isSuccess, attribute }
  }
}

export function _changeAttributeStatusFetch(ids:string[], status:string) {
  const update = ids.map((id) => ({ id, status }))
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: update }),
  }

  return safeFetchJson(`/new_api/attributes/batch`, requestOptions)
}

export async function _changeAttributeOptionStatusFetch(id: string, lineItemIds: string[], status: string) {
  const update = { id, attribute_options: { updates: lineItemIds.map((id) => ({ id, status })) } }
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: update }),
  }

  return safeFetchJson<AttributeApi>(`/new_api/attributes/${id}`, requestOptions)
}

export function parseAttribute(attribute: AttributeApi, mapData?: MapData): Attribute {
  const options = {
    ...mapData,
    defaultData: getDefaultAttribute(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  return parse(attribute, options)
}

function getDefaultAttribute() {
  return parse({}, { fields: initialState.fields })
}

// ------------- Attribute Options -------------

export function getAttributeOptionTitle(attributeOption: AttributeOptions): string {
  return attributeOption.description?.toString()
}

export async function fetchAttributeOptionsByIds(
  ids: (string|number)[],
  data?: Record<string, any>,
  mapData?: MapData,
) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<AttributeOptionsApi>(
    buildGetUrl(`/new_api/attributes/options/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((attributeOption) => parseAttributeOption(attributeOption, mapData)) :
    []
}

export function fetchAttributeOptions(data?: Record<string, any>, mapData?: MapData) {
  return async function fetchAttributeOptionsThunk(dispatch: AppDispatch) {
    const attributeOptions = await _fetchAttributeOptions(data, mapData)
    dispatch({ type: GET_ATTRIBUTE_OPTIONS, payload: attributeOptions })
    return attributeOptions
  }
}

export async function _fetchAttributeOptions(data: Record<string, any>, mapData?: MapData) {
  let attributeOptions = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/attributes/options', data))).json()
    if (result.isSuccess) {
      attributeOptions = result.result.map(
        (attributeOption: AttributeOptionsApi) => parseAttributeOption(attributeOption, mapData),
      )
    }
  } catch (err) {
    console.error(err)
  }

  return attributeOptions
}

export function fetchAttributeOptionsCount(data?: Record<string, any>) {
  return async function fetchAttributeOptionsCountThunk(dispatch: AppDispatch) {
    const count = await _fetchAttributeOptionsCount(data)
    dispatch({ type: GET_ATTRIBUTE_OPTIONS_COUNT, payload: count })
    return count
  }
}

export async function _fetchAttributeOptionsCount(data?: Record<string, any>) {
  let count = 0

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/attributes/options/count', data))).json()
    if (result.isSuccess) {
      count = +result.result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export async function _fetchAttributeOptionIndex(id: string, data: any) {
  let index = 0

  if (id) {
    try {
      const result = await safeFetchJson(buildGetUrl(`/new_api/attributes/options/${id}/index`, data))
      if (result.isSuccess) {
        index = +result.result || 0
      }
    } catch (err) {
      console.error(err)
    }
  }

  return index
}

export function parseAttributeOption(attributeOption: AttributeOptionsApi, mapData?: MapData): AttributeOptions {
  const options = {
    ...mapData,
    defaultData: getDefaultAttributeOption(),
    fields: initialState.optionsFields,
    dataSetName: optionsDataSetName,
  }

  return parse(attributeOption, options)
}

function getDefaultAttributeOption() {
  return parse({}, { fields: initialState.optionsFields })
}
