import React, { useMemo } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'primereact/tooltip'

type SmartGridFlagProps = {
  isVisible?: boolean,
  title?: string,
  icon?: IconProp,
  id: string,
}

function SmartGridIconFlag({
  isVisible = true,
  title,
  icon,
  id,
  children,
}: React.PropsWithChildren<SmartGridFlagProps>) {
  const _id = useMemo(() => {
    return `SmartGridIconFlag_${id.replace('-', '_')}`
  }, [id])

  return (
    <span className="a-smart-grid-icon-flag-container">
      {children}
      {isVisible ? (
        <>
          <FontAwesomeIcon
            icon={icon}
            id={_id}
            className="a-smart-grid-icon-flag"
          />
          <Tooltip
            target={`#${_id}`}
            position="top"
            style={{ textAlign: 'center' }}
          >
            {title}
          </Tooltip>
        </>
      ): null}

    </span>
  )
}

export default SmartGridIconFlag
