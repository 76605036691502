import { TFunction, i18n } from 'i18next'
import { BaseEntity } from 'types/slices'
import { DefaultRecord, getKeys } from 'types/utils'

import { valueOrDefault } from 'utils/defaultValueHelper'
import { DevLogs } from 'utils/devLogs'
import { formKeyDataToObject, toArray } from 'utils/mapperHelper'

import { Resource } from 'reducers/resources/resourcesSlice'
import { SmartFormTranslationPath } from 'reducers/smart-form/smartFormSlice'
import {
  E,
  GetFieldValue,
  GlobalForm,
  LineItemsForm,
  SmartFormContents,
  SmartFormFieldType,
  SmartFormLineItemContents,
  SmartFormRows,
  SmartFormSideEffects,
  SmartFormTranslationType,
  SmartFormUpdate,
  SmartTranslationPath,
} from 'reducers/smart-form/smartFormTypes'
import { handleActionOnField } from 'reducers/smart-form/utils'

export function handleSmartFormTranslationPath<T>(
  fieldTranslationPath: SmartTranslationPath<T>,
  field: keyof T,
  type: SmartFormTranslationType,
) {
  if (typeof fieldTranslationPath === 'function') {
    return fieldTranslationPath(field, type)
  }

  return `${fieldTranslationPath}.${String(field)}.${type}`
}

export function getTranslationFunction(
  translation: SmartFormTranslationPath,
  fieldTranslationPath: SmartTranslationPath,
  type: SmartFormTranslationType,
) {
  if (fieldTranslationPath) {
    return (field) => handleSmartFormTranslationPath(fieldTranslationPath, field, type)
  }

  if (typeof translation === 'function') {
    return translation
  }

  return (fieldKey: string) => `${translation}.${fieldKey}`
}

export function buildUpdatedActiveData<T extends DefaultRecord>(
  activeData: T | undefined,
  globalForm: GlobalForm<T>,
) {
  if (!globalForm) return (activeData ?? {}) as T

  if (!activeData) {
    return formKeyDataToObject(globalForm, { onlyChanged: false, isDatabase: false, isDatabaseNull: false }) as T
  }

  return getKeys(activeData)
    .reduce<T>(
      (acc, key) => {
        const formField = globalForm?.[key]

        if (!formField || !formField.isChanged) return acc

        acc[key] = globalForm[key].value

        return acc
      },
      { ...activeData } as T,
    )
}

export function getFirstTranslation(
  translations: string[],
  t: TFunction,
  i18n: i18n,
  defaultValue: string = '',
) {
  const translation = translations.find((translation) => i18n.exists(translation))

  return translation ? t(translation) : defaultValue
}

function filterField<
  T extends DefaultRecord,
>(_getFieldValue: GetFieldValue<T>, isView: boolean) {
  const hideOnView = (field: SmartFormFieldType) => field.hideOnView && isView
  const showOnlyOnView = (field: SmartFormFieldType) => field.showOnlyOnView && !isView
  const visible = (field: SmartFormFieldType) => typeof field.visible === 'function' ?
    field.visible(_getFieldValue) : valueOrDefault(field.visible, true)

  return (field: SmartFormFieldType) => {
    return visible(field) && !hideOnView(field) && !showOnlyOnView(field)
  }
}

export function filterSmartFormFields<
  T extends DefaultRecord,
>(
  rows: SmartFormRows,
  _getFieldValue: GetFieldValue<T>,
  isView: boolean,
) {
  const filter = filterField(_getFieldValue, isView)

  const filteredFields = rows.reduce((acc, row) => {
    const filteredColumns = row.columns.filter(filter)

    if (filteredColumns.length) {
      acc.push({ ...row, columns: filteredColumns })
    }

    return acc
  }, [])

  return filteredFields
}

export type SmartFormUpdateEvent<T extends DefaultRecord> = {
  field: SmartFormFieldType<T>,
  data: SmartFormUpdate<T> | SmartFormUpdate<T>[],
}

export function formatFormUpdates<T extends DefaultRecord>(
  { field, data }: SmartFormUpdateEvent<T>,
  globalForm: GlobalForm<T>,
) {
  let updatedFields = toArray(data)
  const currentFieldUpdate = updatedFields.find((fieldValue) => fieldValue.field === field.name)

  // ? If the field has a onChange middleware, we call it
  if (field.onChange) {
    updatedFields = field.onChange(updatedFields, currentFieldUpdate, globalForm)
  }

  return updatedFields
}

type SmartFormFieldFromContents<T extends SmartFormContents> = T[number]['rows'][number]['columns'][number]
type SmartFormFieldFromLineItemContents<T extends SmartFormLineItemContents> = T[number]['rows'][number]
type MapOverFieldFunction<T extends SmartFormFieldType> = (field: T) => T

export function mapOverContents<
  T extends SmartFormContents,
  F extends SmartFormFieldFromContents<T>,
>(
  contents: T,
  mapOverField: MapOverFieldFunction<F>,
) {
  return contents.map((content) => ({
    ...content,
    rows: content.rows.map((row) => ({
      ...row,
      columns: row.columns.map(mapOverField),
    })),
  }))
}

export function mapOverLineItemContents<
  T extends SmartFormLineItemContents,
  F extends SmartFormFieldFromLineItemContents<T>,
>(
  contents: T,
  mapOverField: MapOverFieldFunction<F>,
) {
  return contents.map((content) => ({
    ...content,
    rows: content.rows.map(mapOverField),
  }))
}

export function getLineItemData<T extends E>(lineItemsForm: LineItemsForm<T>, lineItemId: T['id']) {
  return lineItemsForm.insertions[lineItemId] || lineItemsForm.updates[lineItemId]
}

export function updateItemFromUpdates<T extends E>(
  updates: SmartFormUpdate<T>[],
  currentGlobalForm: GlobalForm<T>,
  /**
   * If none are passed, the side effects will not be executed.
   */
  sideEffects?: SmartFormSideEffects<T>,
) {
  const changedFieldUpdates = updates.filter((fieldValue) => {
    const fieldData = currentGlobalForm[fieldValue.field]

    if (!fieldData) {
      DevLogs.error(
        'SmartForm',
        'setLineItemsForm',
        `Field \`${String(fieldValue.field)}\` not found in \`lineItemData\``,
        '(Possible cause: The field is not define in the `getFields()` function)',
      )

      return false
    }

    return fieldData.value !== fieldValue.value
  })

  if (changedFieldUpdates.length === 0) return currentGlobalForm

  const updatedGlobalForm = getKeys(currentGlobalForm)
    .reduce((acc, key) => {
      const updatedFieldValue = changedFieldUpdates.find((fieldValue) => fieldValue.field === key)

      acc[key] = {
        ...currentGlobalForm[key],
      }

      if (updatedFieldValue) {
        acc[key].value = handleActionOnField(updatedFieldValue.customUpdate, acc[key].value, updatedFieldValue.value)
        acc[key].isChanged = true
      }

      return acc
    }, {} as GlobalForm<T>)

  if (!sideEffects) {
    return updatedGlobalForm
  }

  DevLogs.log('updateItemFromUpdates', 'Based Updates', changedFieldUpdates)

  const sideEffectUpdates = changedFieldUpdates.flatMap((update) => {
    const sideEffect = sideEffects[update.field]

    if (!sideEffect) return []

    return sideEffect(update.value, updatedGlobalForm, currentGlobalForm)
  })

  DevLogs.log('updateItemFromUpdates', 'Side effects Updates', sideEffectUpdates)

  return updateItemFromUpdates(sideEffectUpdates, updatedGlobalForm)
}

type SocketUpdateIsFromUserParams<T extends BaseEntity> = {
  identification?: Resource,
  loginResource?: Resource,
  update: T,
}

export function socketUpdateIsFromUser<T extends BaseEntity>({
  identification, loginResource, update,
}: SocketUpdateIsFromUserParams<T>) {
  const resourceId = identification?.id ?? loginResource?.id

  /**
   * If no user is connected, or that the updated entity does not have a modifiedById,
   * we do not know if the update is from the user or not.
   */
  if (!resourceId || !update.modifiedById) {
    return false
  }

  return resourceId != update.modifiedById
}
